import { LoginRoutes } from '@/router/routes/Login';
import { NotFound, UserNotFound } from '@/router/routes/NotFound';
import { FAQ } from '@/router/routes/FAQ';
import { Preview } from '@/router/routes/Preview';
import { Profile } from '@/router/routes/Profile';
import { Contact } from '@/router/routes/Contact';
import { LearningProfile } from '@/router/routes/LearningProfile';
import { Catalog } from '@/router/routes/Catalog';
import { CourseDetail } from '@/router/routes/CourseDetail';
import { Course } from '@/router/routes/Course';
import { OpenCatalog } from '@/router/routes/OpenCatalog';
import Config from '@/config';
import { BookingRequest } from '@/router/routes/BookingRequest';

export const generateRoutes = (modules) => {
  let routes = [Course, CourseDetail, Catalog, LearningProfile, Contact, Profile, Preview, FAQ, UserNotFound, BookingRequest];

  if (modules[62]) {
    routes = [OpenCatalog, ...routes];
  } else {
    routes = [LoginRoutes, ...routes];
  }

  let customRoutes = null;
  if (Config.customizing !== 'x-cell') {
    try {
      customRoutes = require('../customizing/router/routes.js');
    } catch (e) {
    }
  }

  const combinedRoutes = customRoutes ? [...routes, ...customRoutes.default] : routes;

  combinedRoutes.push(NotFound);

  return combinedRoutes;
};
