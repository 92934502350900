import store from '../store/RootStore';
export const ModuleUtil = {
  data() {
    return {
      moduleChecker: new ModuleChecker(store.state.mandantStore.enabledModules)
    };
  }
};

export class ModuleChecker {
  constructor(...activeModules) {
    this.activeModules = activeModules;
  }

  isModuleActive(module) {
    return module !== undefined && store.state.mandantStore.enabledModules[module] !== undefined;
  }
}
