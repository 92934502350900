import Vue from 'vue';
import App from './App.vue';
import Maintenance from './views/maintenance/maintenance';
import store from './store/RootStore';
import { getRouter } from './router';
import Resource from 'vue-resource';
import { i18n, i18nMixin } from './i18n';
import configs from './config';
import vueConfig from './utils/config';
import { frontend, MandantInfoService, TokenValidator } from 'am-api-connector-general';
import VueNotifications from 'vue-notifications';
import Noty from 'noty';
import { sync } from 'vuex-router-sync';
import 'url-search-params-polyfill';
import { getBrowserLanguage } from './utils/LanguageDeterminer';
import { Logger } from './utils/Logger';
const emptyFunction = () => {};

const logger = new Logger('InitScript');

if (window.location.host.indexOf('-admin.') > -1) {
  window.location.replace(window.location.protocol + '//' + window.location.host.replace('-admin.', '.') + '/academymaker');
}

function toast({ title, message, type, timeout, cb }) {
  if (type === VueNotifications.types.warn) type = 'warning';
  return new Noty({
    text: message,
    timeout: 10000,
    type,
    theme: 'academymaker',
    layout: 'bottomCenter',
    animation: {
      open: 'noty_effects_open_am',
      close: 'noty_effects_close_am'
    }
  }).show();
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

const infoService = new MandantInfoService(configs.host + '/academies/api');

Vue.config.productionTip = false;
Vue.use(Resource);
Vue.use(vueConfig, configs);
Vue.use(VueNotifications, options);
Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

infoService.getMandantInfoForHost(window.location.hostname).then(data => {
  let params = new URLSearchParams(location.search);
  let ssoLogin = params.get('ssoLogin');
  let xcell = params.get('xcell');
  let promise;
  if (ssoLogin !== null && ssoLogin === 'true') {
    let ref = params.get('ref');
    store.dispatch('userStore/setUserInfo', { language: params.get('lang'), name: params.get('name') });
    let replacedRoute = window.location.href.split('?')[0];
    if (ref) {
      replacedRoute += '#/?ref=' + ref;
    }
    history.replaceState({}, '', replacedRoute);
  } else if (xcell === 'true' || window.location.href.indexOf('/preview/') > -1 || window.location.href.indexOf('/booking-request') > -1) {
    // nothing to do (also skip if preview view is requested)
  } else if (!location.href.includes('/#/user-not-found') && (data.externalLogin === true && (data.ssoSettings.type === 1 || (data.ssoSettings.type === 4 && data.ssoSettings.ssoUrl === window.location.hostname)))) {
    promise = TokenValidator.isValid(configs.host + '/academies/api', emptyFunction).then((tokenIsValid) => {
      if (!tokenIsValid) {
        logger.info('Invalid token starting SSO login...');
        const hashParams = location.hash.substr(3).split('&')
          .map(v => v.split('='))
          .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});
        let ssoUrl;
        if(data.ssoSettings.externUrl) {
          ssoUrl = data.ssoSettings.externUrl;
        }
        else {
          ssoUrl = configs.host + '/academies/api/academyLogin?mandant=' + data.mandantName;
        }
        if (hashParams && hashParams.ref) {
          ssoUrl += '&ref=' + hashParams.ref;
        } else if (location.hash.includes('/faq/')) {
          ssoUrl += '&ref=' + location.hash.substr(1);
        }

        window.location.replace(ssoUrl);

        return true;
      }

      return false;
    });
  }

  const router = getRouter(data.enabledModules);

  sync(store, router, { moduleName: 'routing' });

  let defaultFilter = -1;
  if (data.header.defaultFilter === 'open') {
    defaultFilter = 'notStarted';
  }

  store.dispatch('mandantStore/storeMandantInfo', data);
  store.dispatch('uiStore/filterTrainings', {
    category: -1,
    status: defaultFilter,
    courseType: -1
  });
  if (!store.state.userStore.language) {
    logger.debug('Detected browser language: ' + getBrowserLanguage(data.masterlanguage, Object.keys(data.academyLanguages)));
    store.dispatch('userStore/setLanguage', getBrowserLanguage(data.masterlanguage, Object.keys(data.academyLanguages)));
  }

  let file = document.createElement('link');
  file.rel = 'stylesheet';
  file.href = configs.host + '/academies/api/mandant/stylesheet/' + data.mandantName + '/' + data.trainingcenterId;
  document.head.appendChild(file);

  Vue.use(frontend.VueConnector, {
    version: data.apiVersion,
    host: configs.host,
    mandant: data.mandantName,
    trainingCenterId: data.trainingcenterId,
    modules: data.enabledModules,
    obtainTokenMethod: emptyFunction,
    removeTokenMethod: emptyFunction,
    tokenMethod: emptyFunction
  });

  const vueConfig = {
    el: '#app',
    mixins: [
      i18nMixin
    ],
    i18n,
    router,
    store,
    render: h => h(App)
  };

  if (promise) {
    promise.then((ssoInProgress) => {
      if (!ssoInProgress) {
        new Vue(vueConfig);
      }
    });
  } else {
    new Vue(vueConfig);
  }
}).catch((e) => {
  logger.error(e, 0);
  new Vue({
    el: '#app',
    mixins: [
      i18nMixin
    ],
    i18n,
    store,
    render: h => h(Maintenance)
  });
});

Vue.filter('truncate', function(text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

Vue.http.interceptors.push((request, next) => {
  request.headers.set('Accept-Language', store.state.userStore.language.replace('_', '-'));
  next();
});
