module.exports = {
  "main_navigation": {
    "login": "Login",
    "registration": "Enregistrement",
    "courses": "Ma Formation",
    "coursesDetail": "Détails de la formation",
    "catalog": "Catalogue",
    "learningProfile": "Profil d'apprentissage",
    "contact": "Contact",
    "profile": "Profil",
    "pwLost": "Données de connection oubliées",
    "about": "Imprimer",
    "terms_and_conditions": "Conditions d'utilisation/Protection des données",
    "logout": "Se déconnecter",
    "trainingmanager": "Demandes",
    "trainingmanagerrequest": "Demande",
    "trainingmanagermanage": "Gérer",
    "trainingmanagermanagedetail": "Détail",
    'faq': 'FAQ'
  },
  "general": {
    "year": "Année",
    "month": "Mois",
    "noResult": "Aucun résultat n'a été trouvé.",
    "months": {
      "01": "Janvier",
      "02": "Février",
      "03": "Mars",
      "04": "Avril",
      "05": "Mai",
      "06": "Juin",
      "07": "Juillet",
      "08": "Août",
      "09": "Septembre",
      "10": "Octobre",
      "11": "Novembre",
      "12": "Décembre"
    },
    "category": "Catégorie",
    "calendar": "Calendrier",
    'search': "Recherche",
    "searchError": "Veuillez saisir au moins trois caractères.",
    "showLess": "Voir moins",
    "showMore": "Voir plus",
    'pagination': {
      'next': 'Suivant',
      'prev': 'Précédent',
      'separator': 'sur',
      'pageSize': 'Articles par page'
    }
  },
  "maintenance": {
    "title": "Maintenance",
    "text": "En raison de travaux de maintenance, l'Académie est actuellement indisponible. Veuillez réessayer plus tard."
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    'notFound': {
      'headline': 'Nous sommes vraiment désolés !',
      'text1': 'La page demandée n\'a pas été trouvée.',
      'text2': 'Utilisez le bouton Retour de votre navigateur ou allez à la page d\'accueil.',
      'button': 'Retour à la page d\'accueil'
    },
    "courseCalendar": {
      "expired": "Expiré",
      "soonExpired": "Expire bientôt"
    },
    "profile": {
      "userdata": "Information utilisateur",
      "password": "Mot de passe",
      "genderLab": "Salutation",
      "gender": {
        "men": "Mr",
        "woman": "Mme",
        "diverse": "Divers",
        "unknown": "Non précisé"
      },
      "firstname": "Prénom",
      "lastname": "Nom de famille",
      "email": "Email",
      "strasse": "Rue, Numéro",
      "city": "Ville",
      "phone": "Téléphone",
      "fax": "Fax",
      "position": "Poste",
      "department": "Service",
      "required": "Champ requis",
      "oldPassword": "Ancien mot de passe",
      "newPassword": "Nouveau mot de passe",
      "newPasswordRep": "Répéter le mot de passe",
      "company": "Société",
      "language": "Langage"
    },
    "catalog": {
      "back": "Retour",
      "bookingDialog": {
        "notice": "Veuillez remplir les commentaires pour la demande de commande"
      }
    },
    "contact": {
      "info": "Veuillez saisir votre demande dans le champ du message. Si vous faites référence à une formation, merci de la préciser. Votre message pourra ensuite être transféré directement au bon endroit.",
      "trainingSelection": "Sélection de la formation",
      "yourMessage": "Votre message",
      "success": "Votre  message a été envoyé avec succès",
      "otherReason": "Demande de contact générale",
      "otherReasonSubject": "Autre demande",
      "chooseReason": "Choisissez SVP"
    },
    "learningProfile": {
      "title": "Profil d'apprentissage",
      "unit": "Unité d'apprentissage",
      "points": "Total des points",
      "history": "Historique d'apprentissage",
      "restoreFilter": "Réinitialisez le filtre",
      "certificate": "Certificat",
      'downloadAll': 'Télécharger tout',
      "withoutCategory": "Sans catégorie",
      "tooltip01": "Vous avez",
      "tooltip02": "De",
      "tooltip03": "Unités d'apprentissage achevées",
      'gamification': {
        'badge':{
          'title': 'Récompenses',
          'congratulations': 'Félicitations!',
          'achieved': 'Vous avez reçu une nouvelle récompense!'
        }
      }
    },
    "courseDetail": {
      "title": "Détails de la formation",
      "back": "Revenir à la liste des formations",
      "progress": "Progrès",
      "expiry": "Valable jusqu'à",
      "classroomNo": "Numéro de formation",
      "contactPerson": "Personne contact",
      "targetGroup": "Groupe ciblé",
      "bonusPoints": "Points bonus",
      "classroomContent": "Contenu de la formation",
      "webinarLight": "Information sur le Séminaire",
      "specials": "Particularités",
      "stornoConditions": "Politique d' annulation",
      "meeting": "Rendez-vous",
      'freeDay': 'Free day',
      "speaker": "Formateur / Conférencier",
      "location": "Lieu de formation",
      "requested": "DEMANDÉ",
      "waitlist": "LISTE D'ATTENTE",
      "fullyBooked": "COMPLET",
      "edit": "MODIFIÉ",
      "webinarSpeaker": "Conférenccier",
      "docsAndLinks": "Documents et liens",
      "links": "Liens",
      "webinar": {
        "host": "Séminaire ouvert",
        "state": {
          "ended": "Séminaire terminé",
          "beforeStart": "Le Séminaire commence à"
        }
      },
      "sidebar": {
        "price": "Prix p.P.",
        "duration": "Durée d'apprentissage",
        "to": "à",
        "numberOfParticipants": "Nombre de participants",
        "status": "Etat des réservations",
        'totalStatus': 'État général',
        'contentStatus': 'Statut de la formation'
      },
      "bookingInformation": {
        "title": "Informations sur les réservations",
        "note": "Remarque",
        "accounts": "Centre de coûts",
        "invoiceRecipient": "Destinataire  de la facture",
        "invoiceAddress": "Adresse de facturation",
        "food": "Préférence culinaire",
        "arrival": "Arrivée",
        "arrivalType": {
          "none": "Non précisé",
          "car": "Voiture",
          "train": "Train",
          "plane": "Avion"
        },
        "arrivalDate": "Date d'arrivée",
        "departureDate": "Date de départ",
        "flightNumber": "Numero de vol",
        "railwayStation": "Gare férroviaire"
      },
      "reserve": {
        "title": "Vous ne pouvez pas assister au (x) rendez-vous (s)?",
        "text": "Notifiez votre intérêt! Vous serez alors informé des éventuelles nouvelles dates.",
        "button": "Réserver",
        "prebooked": {
          "text": "Votre réservation pour cet événement/formation a bien été noté."
        }
      },
      'downloads': {
        'modalHeadline': 'Téléchargements',
        'sidebarLink': 'Document(s)',
      }
    },
    "course": {
      "type": {
        "0": "Formation en ligne",
        "1": "Formation en ligne",
        "2": "Formation en ligne",
        "3": "Formation en ligne",
        "4": "Formation en ligne",
        "5": "Formation en ligne",
        "6": "Formation en présentiel",
        "7": "Séminaire",
        "-2": "Projet",
        "undefined": " - "
      },
      "status": {
        "open": "Ouvert",
        'sent': 'Sent',
        "passed": "Réussi",
        'sent': 'Envoyé',
        "failed": "Échoué",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Expiré",
        "booked": "Réservé",
        "started": "En cours",
        "upcoming": "À venir",
        "running": "En cours de réalisation",
        "completed": "Terminé"
      },
      "notification": {
        "success1": "Vous avez terminé la formation",
        "success2": "Réussi"
      },
      "statusLabel": {
        "training": "Formation",
        'content': 'Contenu',
        'attendance': 'Attendance',
        "exam": "Examen",
        "survey": "Sondage"
      },
      "start": {
        "survey": "Commencer l'enquête",
        "exam": "Commencer l'examen"
      },
      "prefix": {
        "category": "Catégorie",
        "state": "Etat  ",
        "type": "Type de formation"
      },
      "noAssigned": "Il n'y a pas aucune unité de formation qui vous est attribuée",
      "noSolved": "Vous n'avez encore terminé aucune formation. Commencez dès maintenant avec votre première unité d'apprentissage dans l'espace «Ma formation»!",
      "noAssignedBookable": "Il n'y a pas aucune réservation de formation qui vous est attribuée",
      "noResult": "Aucune unité de formation correspondant à vos critères n'a été trouvée.",
      "loadingFailed": "Les données demandées n'ont pas pu être chargées en raison d'une erreur inconnue!",
      "noResultInCategory": "Aucune unité de formation n'est affectée à cette catégorie.",
      "meeting": {
        "noMeeting": "Aucun Rendez-vous",
        "multipleMeetings": "Dates diverses"
      },
      "project": {
        "courses": "Formation"
      }
    },
    "login": {
      "error": "Nom d'utilisateur et /ou mot de passe incorrect!",
      "empty": "Veuillez remplir tous les champs requis.",
      "back": "Revenir au login",
      "description": "Entrez svp votre nom d'utilisateur ou votre adresse Email pour recevoir votre login.",
      "password-reset": {
        "description": "Choisissez svp un nouveau mot de passe pour votre compte utilisateur.",
        "success": "Votre mot de passe a été changé avec succés.",
        "error": "Le mot de passe ne correspondent pas."
      },
      "placeholder": "Nom d'utlisateur / Adresse Email",
      "button": "Demander mes données de connection / login",
      "success": "Votre demande a été envoyée avec succés.",
      "errorForgot": "Aucun utilisateur correspondant à vos critères n'a été trouvé.",
      "errorForgotSecurityQuestion": "La réponse que vous avez saisie n'est pas correcte!"
    },
    "registration": {
      "description": "Si vous n'avez pas encore de compte, vous pouvez simplement vous inscrire via le code promotionnel de votre entreprise. Ensuite, vous recevrez immédiatement un e-mail.",
      "actionCodeError": "Code d'enregistrement non valide",
      "actionCodeLimitExceeded": "Le nombre maximum d'enregistrements possibles pour le code entré a été dépassé.",
      "registerDescription": "Veuillez remplir le formulaire. Vous recevrez sous peu vos données de connexion par e-mail.",
      "title": "Enregistrement",
      "required": "Champs requis*",
      "emailError": "L'adresse e-mail que vous avez saisie est déjà utilisée.",
      "send": "Soumettre",
      "completeMessage": "Vous vous êtes inscrit avec succès à l'académie!"
    },
    "dataProtection": {
      "accept": "J'accepte",
      "acceptText": "Oui, j'accepte les conditions d'utilisation/Protection des données."
    },
    "securityQuestion": {
      "title": "Questions de sécurité",
      "chooseQuestion": "Choisissez SVP",
      "questionSelection": "Sélectionnez un question svp",
      "yourAnswer": "Entrez votre réponse svp",
      "description": "Veuillez répondre à la question de sécurité et sélectionner un nouveau mot de passe pour votre compte utilisateur."
    },
    'faq': {
      'title': 'Questions fréquemment posées'
    }
  },
  "sidebar": {
    "all": "Tout",
    "categories": "CATÉGORIES",
    "status": "ETAT",
    "courseType": "TYPE DE FORMATION",
    "topics": "DOMAINES THÉMATIQUES"
  },
  "placeholder": {
    "username": "Nom d'utlisateur",
    "password": "Mot de passe",
    "actionCode": "Code promotionnel"
  },
  "button": {
    "login": "SE CONNECTER",
    "login-sso": "CONNECTION REPERTOIRE ACTIF",
    "send": "ENVOYER",
    "start_course": "DEMARRER ",
    "changePassword": "Changez votre mot de passe",
    "reset": "Réinitialiser",
    "save": "Sauvegarder",
    "request": "Enquête",
    "storno": "Annuler",
    "close": "Fermer",
    "booking": "Reserver",
    "waitlist": "Liste d'attente",
    "start_webinar": "Commencer le Séminaire",
    "open_webinar": "Ouvrir Séminaire en ligne",
    'cancel': 'Annuler',
    'confirm': 'Confirmer'
  },
  "bookingState": {
    "0": "DEMANDÉ",
    "1": "RESERVÉ",
    "2": "LISTE D'ATTENTE",
    "3": "ANNULÉ",
    "4": "ANNULÉ",
    "5": "REFUSÉ",
    "6": "RENDEZ-VOUS RESERVÉ",
    "onlineTraining": {
      "0": "DEMANDÉ",
      "1": "RESERVÉ",
      "2": "REFUSÉ"
    }
  },
  "foodPreference": {
    "none": "Non",
    "lactoseIntolerance": "Intolérance au lactose",
    "vegetarian": "Végétarien",
    "vegan": "Vegan",
    "nutAllergy": "Allergie aux fruits à coque",
    "diabetic": "Diabete",
    "glutenFree": "Sans Gluten",
    "kosher": "Casher",
    "halal": "Halal",
    "other": "Autre"
  },
  "notifications": {
    "error": "Un problème est survenu!",
    "errors": {
      "missingInput": "Veuillez remplir les champs",
      "imageLoad": "L'image demandée n'a pu être chargée"
    },
    "success": {
      "book": {
        "meeting": "Vous avez réservé avec succès la formation en présentiel",
        "webinar": "Vous avez réservé avec succès le Séminaire",
        "course": "Vous avez réservé avec succès la formation ",
        "project": "Vous avez réservé avec succès le projet"
      },
      "request": {
        "meeting": "Vous avez envoyé avec succès votre demande de réservation pour la formation en présentiel",
        "webinar": "Vous avez envoyé avec succès votre demande de réservation pour le Séminaire",
        "course": "Vous avez envoyé avec succès votre demande de réservation pour la formation",
        "project": "Vous avez envoyé avec succès votre demande de réservation pour le projet"
      },
      "profile": {
        "updateSuccess": "Vous avez mis à jour votre profil avec succès!",
        "updatePasswordSuccess": "Vous avez mis à jour votre mot de passe avec succès!"
      },
      "securityQuestion": {
        "questionSave": "Votre question de sécurité a été enregistrée!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "La réservation existe déjà!",
      "noaccess": "Accès non autorisé",
      "notimplementet": "Fonction non disponible pour le moment."
    },
    "success": {
      "booking": "Vous avez réservé avec succès.",
      "request": "Vous avez envoyé avec succès une demande de réservation.",
      "cancel": "Vous avez annulé avec succès.",
      "waitlist": {
        "add": "Vous avez été mis sur la liste d'attente"
      },
      "reservelist": {
        "add": "Vous avez été mis sur la liste des réservations"
      }
    },
    "courseCalendar": {
      "noDate": "Sans délai"
    }
  },
  "trainingmanager": {
    "headline": "Financement de la formation continue",
    "headlinerequest": "Demande de fichiers",
    "bottonrequest": "Demande de fichiers",
    "buttonmanage": "Gérer les applications",
    "requestform": {
      "requester": "Demandeur:",
      "date": "Date:",
      "tab01": "Demande de fichiers",
      "tab02": "Demande de groupe/tiers",
      "tab01headline01": "Nom du participant:",
      "tab01headline02": "Sujet de formation:",
      "tab01headline04": "Noms des participants:",
      "tab01headline03": "Détails du cours:",
      "tab01label01": "Salutation:",
      "tab01label02": "Prénom:",
      "tab01label03": "Nom de famille:",
      "tab01label04": "Mr",
      "tab01label05": "Mme/Mlle",
      "tab01label06": "Catégorie:",
      "tab01label07": "Titre*:",
      "tab01label08": "Description rapide:",
      "tab01label09": "Autre:",
      "tab01label09O": "Autre",
      "tab01label10": "Centre de coûts :",
      "tab01label11": "Date (du/au)",
      "tab01label12": "Numéro du cours:",
      "tab01label13": "Prix:",
      "tab01label14": "Fournisseur:",
      "tab01label15": "Pièce jointe:",
      "tab01label16": "Slectionnez un fichier",
      "tab01label17": "Aucun fichier sélectionné",
      "tab01label18": "Soumettre une demande",
      "tab01label19": "Ajouter une personne",
      "tab01label20": "Annuler",
      "olheadline": "Ajouter un participant",
      "olheadline1": "Nom du participant",
      "olbodytext": "Veuillez sélectionner svp les autres participants au cours ci-dessous.",
      "olerror": "Aucun participant trouvé. Veuillez modifier vos paramètres de recherche."
    },
    "manage": {
      "headline": "Gérer les demandes",
      "headlineInfo": "Enquête sur les participants",
      "bodytextInfo": "Vous trouverez ci-dessous toutes les demandes de formation continue ouvertes avec la demande de traitement.",
      "newRequest": "Envoyer sa propre demande",
      "tableHeadline01": "Entrée",
      "tableHeadline02": "Demandeur",
      "tableHeadline03": "Numéro du cours",
      "tableHeadline04": "Titre ",
      "tableHeadline05": "Date ",
      "tableHeadline06": "Prix  ",
      "tableMark": "Sélectionner tout",
      "buttonapprove": "Accepter",
      "buttondecline": "Refuser",
      "modal": {
        "refuse": {
          "headline": "Message - Demande refusée",
          "bodytext": "",
          "placeholder": "Veuillez saisir le texte svp…",
          "checkbox": "Rejeter la demande sans message",
          "button": "Envoyer",
          "approval": "Autorisation accordée",
          "refuse": "Publication non accordée"
        }
      }
    }
  }
};
