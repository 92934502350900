module.exports = {
  'main_navigation': {
    'login': '登录',
    'registration': '注册',
    'courses': '我的培训',
    'coursesDetail': '培训详情',
    'catalog': '目录',
    'learningProfile': '学习概况',
    'contact': '联系我们',
    'profile': '档案资料',
    'pwLost': '忘记登录信息',
    'about': '版权',
    'terms_and_conditions': '使用条款/隐私政策',
    'logout': '注销',
    'faq': '常见问题',
    'trainingmanager': '申请',
    'trainingmanagerrequest': '申请',
    'trainingmanagermanage': '管理',
    'trainingmanagermanagedetail': '详情'
  },
  'general': {
    'year': '年',
    'month': '月',
    'noResult': '未发现任何结果。',
    'months': {
      '10': '十月',
      '11': '十一月',
      '12': '十二月',
      '01': '一月',
      '02': '二月',
      '03': '三月',
      '04': '四月',
      '05': '五月',
      '06': '六月',
      '07': '七月',
      '08': '八月',
      '09': '九月'
    },
    'category': '类别',
    'calendar': '日历',
    'search': '搜索',
    'searchError': '请至少输入三个字符。',
    'showLess': '显示更少',
    'showMore': '显示更多',
    'pagination': {
      'next': '下一页',
      'prev': '上一篇',
      'separator': '/',
      'pagesize': '每页项目'
    }
  },
  'maintenance': {
    'title': '维护工作',
    'text': '由于维护工作，目前无法使用学院。请稍后再试。'
  },
  'password': {
    'requirements': {
      'title': '密码政策',
      'minimalLength': '长度必须至少是#0。',
      'mandantName': '不得使用 \'#0 \'的概念。',
      'names': '密码不能与名字或姓氏相同。',
      'userName': '密码不能包含您的用户名。',
      'userNameEqual': '密码不能包含您的用户名。',
      'bigLetters': '必须至少有#0个大写字母。',
      'smallLetters': '必须至少有#0个小写字母。',
      'digits': '必须至少有#0个数字。',
      'sameLetters': '不得有超过#0的字符相连。',
      'oldPassword': '不能是旧的密码。',
      'birthday': '密码不得与自己的出生日期一致。',
      'minSymbolsPolicy': '必须至少有#0个特殊数符。',
      'minPasswordLength': '密码长度必须至少是#0个字符。',
      'maxPasswordAttempts': '您还剩余#0次机会来正确输入密码。'
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  'pages': {
    'notFound': {
      'headline': '我们很抱歉！',
      'text1': '未找到所要求的页面',
      'text2': '使用您的浏览器上的返回按钮或进入首页。',
      'button': '返回至首页'
    },
    'courseCalendar': {
      'expired': '已过期',
      'soonExpired': '即将到期'
    },
    'profile': {
      'userdata': '用户数据',
      'password': '密码',
      'genderLab': '称呼',
      'gender': {
        'men': '先生',
        'woman': '女士',
        'diverse': '其他',
        'unknown': '未说明'
      },
      'firstname': '名',
      'lastname': '姓',
      'email': '电子邮件',
      'strasse': '街道/门牌号',
      'city': '邮政编码/地点',
      'phone': '电话',
      'fax': '传真',
      'position': '职位',
      'department': '部门',
      'required': '必填项',
      'oldPassword': '旧密码',
      'newPassword': '新密码',
      'newPasswordRep': '重复密码',
      'company': '公司',
      'language': '语言'
    },
    'catalog': {
      'back': '返回',
      'bookingDialog': {
        'notice': '在此输入您申请培训的原因'
      }
    },
    'contact': {
      'info': '请在消息栏中输入您的请求。如果您指的是一个具体的培训，请说明：如此，您的信息可以直接被转到正确的地方。',
      'trainingSelection': '选择培训',
      'yourMessage': '您的消息',
      'success': '您的消息已被成功发送！',
      'otherReason': '一般性联系请求',
      'otherReasonSubject': '其他请求',
      'chooseReason': '请选择'
    },
    'learningProfile': {
      'title': '学习概况',
      'unit': '学习单位',
      'points': '总得分',
      'history': '学习历史',
      'restoreFilter': '重置过滤器',
      'certificate': '证书',
      'withoutCategory': '无类别',
      'tooltip01': '您已完成了',
      'tooltip02': '个学习单位，共有',
      'tooltip03': '个。',
      'gamification': {
        'badge': {
          'title': '奖章',
          'congratulations': '衷心感谢！',
          'achieved': '您已经获得了一个新的奖章！'
        }
      }
    },
    'courseDetail': {
      'title': '培训详情',
      'back': '返回至列表',
      'progress': '进展',
      'expiry': '有效期至',
      'classroomNo': '培训编号',
      'contactPerson': '联系人',
      'targetGroup': '目标群体',
      'bonusPoints': '奖励分',
      'classroomContent': '培训内容',
      'webinarLight': '网络研讨会信息',
      'specials': '特别之处',
      'stornoConditions': '取消条件',
      'meeting': '预约日期',
      'freeDay': 'Free day',
      'speaker': '培训师/报告人',
      'location': '培训地点',
      'requested': '已申请',
      'waitlist': '等候者名单',
      'fullyBooked': '已订满',
      'edit': '处理',
      'webinarSpeaker': '报告人',
      'docsAndLinks': '文档和链接',
      'links': '网络链接',
      'webinar': {
        'host': '开网络研讨会',
        'state': {
          'ended': '网络研讨会已结束',
          'beforeStart': '网络研讨会开始于 '
        }
      },
      'sidebar': {
        'price': '每人价格',
        'duration': '学习时长',
        'to': '至',
        'numberOfParticipants': '参与者人数',
        'status': '预订状况',
        'totalStatus': '总体状况',
        'contentStatus': '培训状况'
      },
      'bookingInformation': {
        'title': '预订信息',
        'note': '注释',
        'accounts': '成本中心',
        'invoiceRecipient': '帐单接收者',
        'invoiceAddress': '帐单地址',
        'food': '膳食偏好',
        'arrival': '抵达',
        'arrivalType': {
          'none': '未说明',
          'car': '汽车',
          'train': '火车',
          'plane': '飞机'
        },
        'arrivalDate': '抵达日期',
        'departureDate': '出发日期',
        'flightNumber': '航班号',
        'railwayStation': '火车站'
      },
      'reserve': {
        'title': '您不能安排预约吗？',
        'text': '请预先登记您的意向！然后，您将被告知可预约的新日期。',
        'button': '预先登记',
        'prebooked': {
          'text': '您已预先登记了您对参加培训的意向。'
        }
      },
      'downloads': {
        'modalHeadline': '下载',
        'sidebarLink': '文件',
      }
    },
    'course': {
      'type': {
        '0': '在线培训',
        '1': '在线培训',
        '2': '在线培训',
        '3': '在线培训',
        '4': '在线培训',
        '5': '在线培训',
        '6': '面授培训',
        '7': '网络研讨会',
        '-2': '项目',
        'undefined': '-'
      },
      'status': {
        'open': '未处理的',
        'passed': '已通过',
        'sent': '发送',
        'failed': '未通过',
        'expired': '已过期',
        'booked': '已预订',
        'started': '处理中',
        'upcoming': '即将到来',
        'running': '进行中',
        'completed': '已结束',
        'notyet': '尚不可用'
      },
      'notification': {
        'success1': '您已通过 ',
        'success2': ' 培训！'
      },
      'statusLabel': {
        'training': '培训',
        'content': '内容',
        'attendance': 'Attendance',
        'exam': '测试',
        'survey': '问卷',
        'efficacyControl': '有效性检查'
      },
      'start': {
        'survey': '开始问卷',
        'exam': '开始测试',
        'efficacyControl': '开始有效性检查'
      },
      'prefix': {
        'category': '类别',
        'state': '状况',
        'type': '培训类型'
      },
      'noAssigned': '未给您安排培训。',
      'noSolved': '您尚未完成任何培训。现在就在“我的培训”区域开始您的首个学习单元!',
      'noAssignedBookable': '未给您安排可预订的培训。',
      'noResult': '未发现符合标准的培训。',
      'loadingFailed': '由于一个未知的错误，所请求的记录无法被加载！',
      'noResultInCategory': '目前尚无课程被分配到此类别。',
      'meeting': {
        'noMeeting': '无预约日期',
        'multipleMeetings': '其他预约日期'
      },
      'project': {
        'courses': '培训'
      }
    },
    'login': {
      'invalidUser': '无效的登录。用户名/密码错误！',
      'invalidPassword': '无效的登录。您还剩余#0次机会来正确输入密码。',
      'accountBlocked': '您的账户已被冻结。请联系负责的工作人员。',
      'error': '用户名和/或密码错误！',
      'empty': '请填写所有字段。',
      'back': '返回至登录',
      'description': '请输入您的用户名或您的电子邮件地址，以便重新发送访问数据。',
      'password-reset': {
        'description': '请为您的用户账户选择一个新的密码。',
        'success': '您的密码已被成功更改。',
        'error': '输入的密码不一致。'
      },
      'placeholder': ' 用户名/电子邮件地址',
      'button': '请求访问数据',
      'success': '请求已被成功发送。',
      'errorForgot': '未找到符合指定标准的用户。',
      'errorForgotSecurityQuestion': '遗憾的是，此答案不正确！'
    },
    'registration': {
      'description': '如果您尚无任何登录数据，您可以直接使用贵司的活动代码进行注册。随后，将立即向您发送一封电子邮件。',
      'actionCodeError': '输入的注册码无效',
      'actionCodeLimitExceeded': '已达到输入的活动代码可注册的最大数量。',
      'registerDescription': '请填写以下表格。您的访问数据将在几分钟内通过电子邮件发送给您。',
      'title': '您的学院注册',
      'required': '必填项*',
      'emailError': '您输入的电子邮件地址已被占用。',
      'emailErrorNotAllowed': '不能用这个电子邮件地址进行注册。',
      'send': '提交',
      'completeMessage': '您已成功注册了学院！'
    },
    'dataProtection': {
      'accept': '同意',
      'acceptText': '是的，我同意使用条款/隐私政策'
    },
    'securityQuestion': {
      'title': '安全问题',
      'chooseQuestion': '请选择',
      'questionSelection': '请选择一个问题',
      'yourAnswer': '请输入您的答案',
      'description': '请回答安全问题并为您的用户账户选择一个新密码。'
    },
    'faq': {
      'title': '常见问题'
    }
  },
  'sidebar': {
    'all': '所有',
    'categories': '类别',
    'status': '状况',
    'courseType': '培训类型',
    'topics': '主题领域'
  },
  'placeholder': {
    'username': '用户名',
    'password': '密码',
    'actionCode': '活动代码'
  },
  'button': {
    'login': '登录',
    'login-sso': 'Active-directory登录',
    'send': '发送',
    'start_course': '启动',
    'changePassword': '更改密码',
    'reset': '重置',
    'save': '储存',
    'request': '申请',
    'storno': '取消',
    'canceled': '预约被取消',
    'close': '关闭',
    'booking': '预订',
    'waitlist': '等候者名单',
    'start_webinar': '开始网络研讨会',
    'open_webinar': '开网络研讨会',
    'cancel': '取消',
    'confirm': '确认'
  },
  'bookingState': {
    '0': '已申请',
    '1': '已预订',
    '2': '等候者名单',
    '3': '已取消',
    '4': '已取消',
    '5': '被拒绝',
    '6': '已预先登记',
    'onlineTraining': {
      '0': '已申请',
      '1': '已预订',
      '2': '被拒绝'
    }
  },
  'foodPreference': {
    'none': '无',
    'lactoseIntolerance': '乳糖不耐症',
    'vegetarian': '素食者',
    'vegan': '纯素食者',
    'nutAllergy': '坚果过敏症',
    'diabetic': '糖尿病',
    'glutenFree': '无麸质食品',
    'kosher': '犹太洁食',
    'halal': '清真食品',
    'other': '其他'
  },
  'notifications': {
    'error': '出了点问题！',
    'errors': {
      'missingInput': '请填写字段',
      'imageLoad': '无法加载所请求的图像'
    },
    'success': {
      'book': {
        'meeting': '您已成功预订了面授培训！',
        'webinar': '您已成功预订了网络研讨会！',
        'course': '您已成功预订了培训！',
        'project': '您已成功预订了项目！'
      },
      'request': {
        'meeting': '您已成功申请了面授培训！',
        'webinar': '您已成功申请了网络研讨会！',
        'course': '您已成功申请了培训！',
        'project': '您已成功申请了项目！'
      },
      'profile': {
        'updateSuccess': '你已成功更新了您的档案资料！',
        'updatePasswordSuccess': '你已成功更新了您的密码！'
      },
      'securityQuestion': {
        'questionSave': '您的安全问题已被保存！'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': '已存在预定！',
      'noaccess': '不允许访问。',
      'notimplementet': '该功能目前尚不可用。'
    },
    'success': {
      'booking': '您已成功预订。',
      'request': '您已成功申请。',
      'cancel': '您已成功取消。 ',
      'waitlist': {
        'add': '您已被列入等候者名单'
      },
      'reservelist': {
        'add': '您已被列入预先登记者名单'
      }
    },
    'courseCalendar': {
      'noDate': '无截止日期'
    }
  },
  'trainingmanager': {
    'headline': '继续教育要求',
    'headlinerequest': '提出申请',
    'bottonrequest': '提出申请',
    'buttonmanage': '管理申请',
    'requestform': {
      'requester': '申请人：',
      'date': '日期：',
      'tab01': '提出请求',
      'tab02': '为团体/第三方提的请求',
      'tab01headline01': '参与者的姓名：',
      'tab01headline02': '继续教育的主题：',
      'tab01headline04': '参与者的姓名：',
      'tab01headline03': '课程详情：',
      'tab01label01': '称呼：',
      'tab01label02': '名：',
      'tab01label03': '姓：',
      'tab01label04': '先生',
      'tab01label05': '女士',
      'tab01label06': '类别：',
      'tab01label07': '头衔*：',
      'tab01label08': '简要描述：',
      'tab01label09': '其他：',
      'tab01label09O': '其他',
      'tab01label10': '成本中心：',
      'tab01label11': '预约日期（从/到）：*',
      'tab01label12': '课程编号：',
      'tab01label13': '价格：',
      'tab01label14': '提供方：',
      'tab01label15': '附录：',
      'tab01label16': '选择文件',
      'tab01label17': '无选中文件......',
      'tab01label18': '提交申请',
      'tab01label19': '添加另一个人',
      'tab01label20': '中断',
      'olheadline': '添加参与者',
      'olheadline1': '参与者的姓名',
      'olbodytext': '请在下面选择其他培训参与者的姓名。',
      'olerror': '未找到参与者。请改变您的搜索参数。'
    },
    'manage': {
      'headline': '管理申请',
      'headlineInfo': '员工请求',
      'bodytextInfo': '您将在下方找到所有未处理的继续教育咨询以及处理请求。',
      'newRequest': '发送自己的申请',
      'tableHeadline01': '入口通道',
      'tableHeadline02': '申请人',
      'tableHeadline03': '课程编号',
      'tableHeadline04': '头衔',
      'tableHeadline05': '预约日期',
      'tableHeadline06': '价格',
      'tableMark': '标记所有',
      'buttonapprove': '授予许可',
      'buttondecline': '不予许可',
      'modal': {
        'refuse': {
          'headline': '消息 - 拒绝请求',
          'bodytext': '',
          'placeholder': '请输入文本......',
          'checkbox': '拒绝无消息的请求',
          'button': '发送',
          'approval': '已授予许可',
          'refuse': '不予许可'
        }
      }
    }
  }
}
