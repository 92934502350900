module.exports = {
  'main_navigation': {
    'login': 'Login',
    'registration': 'Registrierung',
    'courses': 'Meine Trainings',
    'coursesDetail': 'Training Details',
    'catalog': 'Katalog',
    'learningProfile': 'Lernprofil',
    'contact': 'Kontakt',
    'profile': 'Profil',
    'pwLost': 'Logindaten vergessen?',
    'about': 'Impressum',
    'terms_and_conditions': 'Nutzungsbedingungen / Datenschutz',
    'logout': 'Abmelden',
    'faq': 'FAQ',
    'trainingmanager': 'Anträge',
    'trainingmanagerrequest': 'Anfrage',
    'trainingmanagermanage': 'Manage',
    'trainingmanagermanagedetail': 'Detail'
  },
  'general': {
    'year': 'Jahr',
    'month': 'Monat',
    'noResult': 'Es wurde kein Ergebniss gefunden.',
    'months': {
      '01': 'Januar',
      '02': 'Februar',
      '03': 'März',
      '04': 'April',
      '05': 'Mai',
      '06': 'Juni',
      '07': 'Juli',
      '08': 'August',
      '09': 'September',
      '10': 'Oktober',
      '11': 'November',
      '12': 'Dezember'
    },
    'category': 'Kategorie',
    'calendar': 'Kalender',
    'search': 'Suche',
    'searchError': 'Bitte geben Sie mindestens drei Zeichen ein.',
    'showLess': 'Weniger Anzeigen',
    'showMore': 'Mehr Anzeigen',
    'pagination': {
      'next': 'Weiter',
      'prev': 'Zurück',
      'separator': 'von',
      'pageSize': 'Ansicht pro Seite'
    }
  },
  'maintenance': {
    'title': 'Wartungsarbeiten',
    'text': 'Auf Grund von Wartungsarbeiten steht die Akademie momentan nicht zur Verfügung. Bitte versuchen Sie es später erneut.',
  },
  'password': {
    'requirements': {
      'title': 'Passwortrichtlinien',
      'minimalLength': 'Die Länge muss mindestens #0 betragen.',
      'mandantName': 'Der Begriff "#0" darf nicht verwendet werden.',
      'names': 'Das Passwort darf nicht gleich Vor- oder Nachname sein.',
      'userName': 'Das Password darf nicht deine Benutzernamen enthalten.',
      'userNameEqual': 'Das Password darf nicht deine Benutzernamen enthalten.',
      'bigLetters': 'Es muss/müssen mindestens #0 Großbuchstaben vorhanden sein.',
      'smallLetters': 'Es muss/müssen mindestens #0 Kleinbuchstaben vorhanden sein.',
      'digits': 'Es muss/müssen mindestens #0 Zahl/en vorhanden sein.',
      'sameLetters': 'Es dürfen nicht mehr als #0 Zeichen aufeinander folgen.',
      'oldPassword': 'Es darf nicht das alte Passwort sein.',
      'birthday': 'Das Passwort darf nicht dem eigenen Geburtsdatum entsprechen.',
      'minSymbolsPolicy': 'Es muss/müssen mindestens #0 Sonderzeichen vorhanden sein.',
      'minPasswordLength': 'Das Passwort muss mindestens #0 Zeichen lang sein.',
      'maxPasswordAttempts': 'Sie haben noch #0 verbleibene Versuche übrig das Password richtig einzugeben.'
}
  },
  'pages': {
    'bossBooking': {
      'title': 'Buchungsanfrage',
      'subTitle': {
        'accept': 'Möchten Sie die Buchungsanfrage akzeptieren?',
        'decline': 'Möchten Sie die Buchungsanfrage ablehnen?',
      },
      'user': 'Benutzer',
      'training': 'Training',
      'meeting': 'Termin',
      'time': 'Uhrzeit',
      'location': 'Location',
      'project': 'Projekt',
      'reason': 'Geben Sie hier optional eine Begründung ein...',
      'accept': 'Akzeptieren',
      'decline': 'Ablehnen',
      'home': 'Zur Startseite',
      'result': {
        'accept': 'Die Buchungsanfrage wurde akzeptiert.',
        'decline': 'Die Buchungsanfrage wurde abgelehnt.',
        'error': 'Der Buchungsvorgang existiert nicht oder ist bereits abgeschlossen.'
      }
    },
    'booking': {
      'cancel': {
        'title': 'Buchung Stornieren',
        'confirm': 'Sind Sie sicher, dass Sie Ihre Buchung stornieren möchten?'
      }
    },
    'notFound': {
      'headline': 'Es tut uns leid!',
      'text1': 'Die angeforderte Seite wurde nicht gefunden',
      'text2': 'Nutzen Sie die Zurück-Taste Ihres Browsers oder gehen Sie zur Startseite.',
      'button': 'Zurück zur Startseite'
    },
    'userNotFound': {
      'headline': 'Bei Ihrer Anmeldung ist ein Fehler aufgetreten!',
      'text': 'Ihr Organisationsbenutzer konnte nicht gefunden werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie Ihren Ansprechpartner oder Vorgesetzten.',
      'button': 'Erneut versuchen'
    },
    'courseCalendar': {
      'expired': 'Abgelaufen',
      'soonExpired': 'Bald ablaufend'
    },
    'profile': {
      'userdata': 'Benutzerdaten',
      'password': 'Passwort',
      'genderLab': 'Anrede',
      'gender': {
        'men': 'Herr',
        'woman': 'Frau',
        'diverse': 'Divers',
        'unknown': 'Keine Angabe'
      },
      'firstname': 'Vorname',
      'lastname': 'Nachname',
      'email': 'E-Mail',
      'strasse': 'Strasse / Nr.',
      'city': 'PLZ / Ort',
      'phone': 'Telefon',
      'fax': 'Fax',
      'position': 'Position',
      'department': 'Abteilung',
      'required': 'Pflichtfeld',
      'oldPassword': 'Altes Passwort',
      'newPassword': 'Neues Passwort',
      'newPasswordRep': 'Wiederholung',
      'company': 'Unternehmen',
      'language': 'Sprache'
    },
    'catalog': {
      'back': 'Zurück',
      'bookingDialog': {
        'notice': 'Geben Sie hier Ihre Begründung zur Trainingsanfrage ein'
      }
    },
    'contact': {
      'info': 'Bitte geben Sie Ihre Anfrage in das Nachrichtenfeld ein. Wenn Sie sich auf ein spezielles Training beziehen, geben Sie dieses bitte an: Ihre Nachricht kann so direkt an die richtige Stelle geleitet werden.',
      'trainingSelection': 'Trainingsauswahl',
      'yourMessage': 'Ihre Nachricht',
      'success': 'Ihre Nachricht wurde erfolgreich gesendet!',
      'otherReason': 'Generelle Kontaktanfrage',
      'otherReasonSubject': 'Andere Anfrage',
      'chooseReason': 'Bitte wählen Sie'
    },
    'learningProfile': {
      'title': 'Lernprofil',
      'unit': 'Lerneinheiten',
      'points': 'Gesamtpunktzahl',
      'history': 'Lernhistorie',
      'restoreFilter': 'Filter zurücksetzen',
      'certificate': 'Zertifikat',
      'downloadAll': 'Alle Herunterladen',
      'withoutCategory': 'Ohne Kategorie',
      'tooltip01': 'Sie haben',
      'tooltip02': 'von',
      'tooltip03': 'Lerneinheiten absolviert.',
      'gamification': {
        'badge':{
          'title': 'Auszeichnungen',
          'congratulations': 'Herzlichen Glückwunsch!',
          'achieved': 'Sie haben eine neue Auszeichnung erhalten!'
        }
      }
    },
    'courseDetail': {
      'noMeeting': 'Es wurde noch kein Termin für dieses Training angelegt oder alle Termine sind bereits abgelaufen.',
      'title': 'Trainingsdetails',
      'back': 'Zurück zur Liste',
      'progress': 'Fortschritt',
      'expiry': 'gültig bis',
      'classroomNo': 'Schulungsnummer',
      'contactPerson': 'Kontaktperson',
      'targetGroup': 'Zielgruppe',
      'bonusPoints': 'Bonuspunkte',
      'classroomContent': 'Schulungsinhalte',
      'webinarLight': 'Webinar Informationen',
      'specials': 'Besonderheiten',
      'stornoConditions': 'Stornierungsbedingungen',
      'meeting': 'Termine',
      'freeDay': 'Freier Tag',
      'speaker': 'Trainer / Referent',
      'location': 'Schulungsort',
      'requested': 'ANGEFRAGT',
      'waitlist': 'WARTELISTE',
      'fullyBooked': 'AUSGEBUCHT',
      'edit': 'Bearbeiten',
      'webinarSpeaker': 'Referent',
      'docsAndLinks': 'Dokumente und Links',
      'links': 'Links',
      'deadline': 'Anmeldestopp',
      'stornoDeadline': 'Stornostopp',
      'webinar': {
        'host': 'Webinar eröffnen',
        'state': {
          'ended': 'Webinar ist beendet',
          'beforeStart': 'Webinar startet am '
        }
      },
      'sidebar': {
        'price': 'Preis p. P.',
        'duration': 'Lerndauer',
        'to': 'bis',
        'numberOfParticipants': 'Teilnehmerzahl',
        'status': 'Buchungsstatus',
        'totalStatus': 'Gesamtstatus',
        'contentStatus': 'Trainingsstatus',
      },
      'bookingInformation': {
        'title': 'Buchungsinformation',
        'note': 'Anmerkung',
        'accounts': 'Kostenstelle',
        'invoiceRecipient': 'Rechnungsempfänger',
        'invoiceAddress': 'Rechnungsadresse',
        'food': 'Verpflegungspräferenz',
        'arrival': 'Anreise',
        'arrivalType': {
          'none': 'Keine Angabe',
          'car': 'Auto',
          'train': 'Zug',
          'plane': 'Flugzeug'
        },
        'arrivalDate': 'Anreisedatum',
        'departureDate': 'Abreisedatum',
        'flightNumber': 'Flugnummer',
        'railwayStation': 'Bahnhof'
      },
      'reserve': {
        'title': 'Sie können den/die Termin(e) nicht einrichten?',
        'text': 'Merken Sie Ihr Interesse vor! Sie werden dann über mögliche neue Termine informiert.',
        'button': 'Vormerken',
        'prebooked': {
          'text': 'Sie haben Ihr Interesse für dieses Training vorgemerkt.',
        },
      },
      'downloads': {
        'modalHeadline': 'Downloads',
        'sidebarLink': 'Dokument(e)',
      }
    },
    'course': {
      'type': {
        '0': 'Online Training',
        '1': 'Online Training',
        '2': 'Online Training',
        '3': 'Online Training',
        '4': 'Online Training',
        '5': 'Online Training',
        '6': 'Präsenztraining',
        '7': 'Webinar',
        '-2': 'Projekt',
        'undefined': '-'
      },
      'status': {
        'open': 'Offen',
        'sent': 'Abgesendet',
        'passed': 'Bestanden',
        'failed': 'Nicht Bestanden',
        'participated': 'Teilgenommen',
        'notParticipated': 'Nicht Teilgenommen',
        'expired': 'Abgelaufen',
        'booked': 'Gebucht',
        'started': 'In Bearbeitung',
        'upcoming': 'Bevorstehend',
        'running': 'Laufend',
        'completed': 'Absolviert',
        'notyet': 'Noch nicht verfügbar'
      },
      'notification': {
        'success1': 'Sie haben das Training ',
        'success2': ' bestanden!',
      },
      'statusLabel': {
        'training': 'Training',
        'content': 'Inhalt',
        'attendance': 'Anwesenheit',
        'exam': 'Prüfung',
        'survey': 'Befragung',
        'efficacyControl': 'Wirksamk.-Kontrolle'
      },
      'start': {
        'survey': 'Befragung starten',
        'exam': 'Prüfung starten',
        "efficacyControl": "Wirksamkeitskontrolle starten"
      },
      'prefix':{
        'category' : 'Kategorie',
        'state' : 'Status',
        'type' : 'Trainingstyp'
      },
      'noAssigned': 'Ihnen sind keine Trainings zugewiesen.',
      'noSolved': 'Sie haben noch keine Trainings absolviert. Starten Sie jetzt im Bereich "Meine Trainings" mit Ihrer ersten Lerneinheit!',
      'noAssignedBookable': 'Ihnen sind keine buchbaren Trainings in dieser Kategorie zugewiesen.',
      'noResult': 'Es wurden keine Trainings gefunden die den Kriterien entsprechen.',
      'loadingFailed': 'Die angeforderten Datensätze konnten aufgrund eines unbekannten Fehlers nicht geladen werden!',
      'noResultInCategory': 'Der Kategorie wurden noch keine Trainings zugewiesen.',
      'meeting': {
        'noMeeting': 'kein Termin',
        'multipleMeetings': 'div. Termine'
      },
      'project': {
        'courses': 'Trainings'
      }
    },
    'login': {
      'invalidUsername': 'Ungültiger Login. Benutzername ist falsch!',
      'invalidUser': 'Ungülltiger Login. Benutzername / Passwort ist falsch!',
      'invalidPassword': 'Ungültiger Login. Sie haben noch #0 verbleibene Versuche übrig das Password richtig einzugeben.',
      'accountBlocked': 'Ihr Konto wurde gesperrt. Bitte wenden Sie sich an einen verantwortlichen Mitarbeiter.',
      'error': 'Benutzername und/oder Passwort falsch!',
      'empty': 'Bitte füllen Sie alle Felder aus.',
      'back': 'Zurück zum Login',
      'description': 'Geben Sie bitte Ihren Benutzernamen oder Ihre E-Mail-Adresse für die erneute Zusendung der Zugangsdaten an.',
      'password-reset': {
        'description': 'Bitte wählen Sie ein neues Passwort für Ihr Benutzerkonto.',
        'success': 'Ihr Passwort wurde erfolgreich geändert.',
        'error': 'Die angegebenen Passwörter stimmen nicht überein.'
      },
      'placeholder': ' Benutzername / E-Mail Adresse',
      'button': 'Zugangsdaten anfordern',
      'success': 'Anfrage erfolgreich gesendet.',
      'errorForgot': 'Es konnte kein Benutzer gefunden werden, der den angegebenen Kriterien entspricht.',
      'errorForgotSecurityQuestion': 'Die Antwort ist leider nicht korrekt!',
    },
    'registration': {
      'description': 'Sollten Sie noch keine Login-Daten haben, können Sie sich einfach über den Aktionscode ihres Unternehmens registrieren. Im Anschluss daran wird Ihnen umgehend eine E-Mail zugestellt.',
      'actionCodeError': 'Der eingegebene Registrierungscode ist ungültig',
      'actionCodeLimitExceeded': 'Die maximale Anzahl an möglichen Registrierungen für den eingegebenen Aktionscode ist aufgebraucht.',
      'registerDescription': 'Bitte füllen Sie unten stehendes Formular aus. Ihre Zugangsdaten werden Ihnen in wenigen Minuten per E-Mail zugeschickt.',
      'title': 'Ihre Akademie Registrierung',
      'required': 'Pflichtfeld*',
      'emailError': 'Die von Ihnen angegebene E-Mail Adresse ist bereits vergeben.',
      'emailErrorNotAllowed': 'Mit dieser E-Mail Adresse ist eine Registrierung nicht möglich.',
      'send': 'Absenden',
      'completeMessage': 'Sie haben sich erfolgreich für die Akademie registriert!'
    },
    'dataProtection': {
      'accept': 'Zustimmen',
      'acceptText': 'Ja, ich willige den Nutzungsbedingungen / Datenschutz ein'
    },
    'securityQuestion': {
      'title': 'Sicherheitsabfrage',
      'chooseQuestion': 'Bitte wählen Sie',
      'questionSelection': 'Bitte wählen sie eine Frage',
      'yourAnswer': 'Bitte geben sie ihre Antwort ein',
      'description': 'Bitte beantworten Sie die Sicherheitsabfrage und wählen ein neues Passwort für Ihr Benutzerkonto.'
    },
    'faq': {
      'title': 'Häufig gestellte Fragen'
    }
  },
  'sidebar': {
    'all': 'Alle',
    'categories': 'KATEGORIEN',
    'status': 'STATUS',
    'courseType': 'TRAININGSTYP',
    'topics': 'THEMENBEREICHE'
  },
  'placeholder': {
    'username': 'Benutzername',
    'password': 'Passwort',
    'actionCode': 'Aktionscode'
  },
  'button': {
    'login': 'ANMELDEN',
    'login-sso': 'ACTIVE-DIRECTORY LOGIN',
    'send': 'SENDEN',
    'start_course': 'START',
    'changePassword': 'Passwort ändern',
    'reset': 'Zurücksetzen',
    'save': 'Speichern',
    'request': 'Anfrage',
    'storno': 'Stornieren',
    'canceled': 'Termin storniert',
    'close': 'Schließen',
    'booking': 'Buchen',
    'waitlist': 'Warteliste',
    'start_webinar': 'Webinar Starten',
    'open_webinar': 'Webinar Öffnen',
    'cancel': 'Abbrechen',
    'confirm': 'Bestätigen'
  },
  'bookingState': {
    '0': 'ANGEFRAGT',
    '1': 'GEBUCHT',
    '2': 'WARTELISTE',
    '3': 'STORNIERT',
    '4': 'STORNIERT',
    '5': 'ABGELEHNT',
    '6': 'VORGEMERKT',
    'onlineTraining': {
      '0': 'ANGEFRAGT',
      '1': 'GEBUCHT',
      '2': 'ABGELEHNT'
    }
  },
  'foodPreference': {
    'none': 'Keine',
    'lactoseIntolerance': 'Laktose Intoleranz',
    'vegetarian': 'Vegetarisch',
    'vegan': 'Vegan',
    'nutAllergy': 'Nuss Allergie',
    'diabetic': 'Diabetes',
    'glutenFree': 'Glutenfrei',
    'kosher': 'Kosher',
    'halal': 'Halal',
    'other': 'Sonstige',
  },
  'notifications': {
    'error': 'Es ist etwas schief gelaufen!',
    'errors': {
      'missingInput': 'Bitte füllen sie die Felder aus',
      'imageLoad': 'Das angeforderte Bild konnte nicht geladen werden'
    },
    'success': {
      'book': {
        'meeting': 'Sie haben das Präsenztraining erfolgreich gebucht.',
        'webinar': 'Sie haben das Webinar erfolgreich gebucht.',
        'course': 'Sie haben das Training erfolgreich gebucht.',
        'project': 'Sie haben das Projekt erfolgreich gebucht.'
      },
      'request': {
        'meeting': 'Sie haben das Präsenztraining erfolgreich angefragt.',
        'webinar': 'Sie haben das Webinar erfolgreich angefragt.',
        'course': 'Sie haben das Training erfolgreich angefragt.',
        'project': 'Sie haben das Projekt erfolgreich angefragt.'
      },
      'profile': {
        'updateSuccess': 'Sie haben Ihr Profil erfolgreich aktualisiert!',
        'updatePasswordSuccess': 'Sie haben Ihr Passwort erfolgreich aktualisiert!'
      },
      'securityQuestion': {
        'questionSave': 'Ihre Sicherheitsabfrage wurde gespeichert!'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': 'Die Buchung existiert schon!',
      'noaccess': 'Zugriff nicht erlaubt.',
      'notimplementet': 'Funktion ist im Moment nicht verfügbar.'
    },
    'success': {
      'booking': 'Sie haben erfolgreich gebucht.',
      'request': 'Sie haben erfolgreich angefragt.',
      'cancel': 'Sie haben erfolgreich storniert. ',
      'waitlist': {
        'add': 'Sie wurden auf die Warteliste gesetzt'
      },
      'reservelist':{
        'add': 'Sie wurden auf die Vormerkliste gesetzt'
      }
    },
    'courseCalendar': {
      'noDate': 'Ohne Frist'
    }
  },
  'trainingmanager': {
    'headline': 'Weiterbildungsanforderung',
    'headlinerequest': 'Antrag stellen',
    'bottonrequest': 'Antrag stellen',
    'buttonmanage': 'Anträge verwalten',
    'requestform': {
      'requester': 'Antragsteller:',
      'date': 'Datum:',
      'tab01': 'Anforderung stellen',
      'tab02': 'Anforderung für Gruppe/Dritte',
      'tab01headline01': 'Name des Teilnehmers:',
      'tab01headline02': 'Thema der Weiterbildung:',
      'tab01headline04': 'Name der Teilnehmer:',
      'tab01headline03': 'Kursdetails:',
      'tab01label01': 'Anrede:',
      'tab01label02': 'Vorname:',
      'tab01label03': 'Name:',
      'tab01label04': 'Herr',
      'tab01label05': 'Frau',
      'tab01label06': 'Kategorie:',
      'tab01label07': 'Titel*:',
      'tab01label08': 'Kurzbeschreibung:',
      'tab01label09': 'Sonstige:',
      'tab01label09O': 'Sonstige',
      'tab01label10': 'Kostenstelle:',
      'tab01label11': 'Termin (von/bis):*',
      'tab01label12': 'Kursnummer:',
      'tab01label13': 'Preis:',
      'tab01label14': 'Anbieter:',
      'tab01label15': 'Anhang:',
      'tab01label16': 'Datei auswählen',
      'tab01label17': 'Keine Datei ausgewählt...',
      'tab01label18': 'Antrag absenden',
      'tab01label19': 'Weitere Person hinzufügen',
      'tab01label20': 'Abbrechen',
      'olheadline': 'Teilnehmer hinzufügen',
      'olheadline1': 'Name des Teilnehmers',
      'olbodytext': 'Wählen Sie nachfolgend die Namen der weiteren Trainingsteilnehmer aus.',
      'olerror': 'Keine Teilnehmer gefunden. Bitte ändern Sie Ihre Suchparameter.',
    },
    'manage': {
      'headline': 'Anträge verwalten',
      'headlineInfo': 'Mitarbeiteranfragen',
      'bodytextInfo': 'Nachfolgend finden Sie alle offenen Weiterbildungsanfragen mit der Bitte um Bearbeitung.\n',
      'newRequest': 'Eigenen Antrag senden',
      'tableHeadline01': 'Eingang',
      'tableHeadline02': 'Antragssteller',
      'tableHeadline03': 'Kursnr.',
      'tableHeadline04': 'Titel',
      'tableHeadline05': 'Termin',
      'tableHeadline06': 'Preis',
      'tableMark': 'Alle markieren',
      'buttonapprove': 'Genehmigung erteilen',
      'buttondecline': 'Freigabe nicht erteilen',
      'modal': {
        'refuse': {
          'headline': 'Nachricht - Anforderung ablehnen',
          'bodytext': '',
          'placeholder': 'Bitte geben Sie einen Text ein....',
          'checkbox': 'Anforderung ohne Nachricht ablehnen',
          'button': 'Senden',
          'approval': 'Genehmigung erteilt',
          'refuse': 'Freigabe nicht erteilt'
        }
      }
    }
  }
};
