module.exports = {
  "main_navigation": {
    "login": "Prijava",
    "registration": "Registracija",
    "courses": "Moje obuke",
    "coursesDetail": "Detalji obuke",
    "catalog": "Katalog",
    "learningProfile": "Profil učenja",
    "contact": "Kontakt",
    "profile": "Profil",
    "pwLost": "Zaboravili ste podatke za prijavu?",
    "about": "Impresum",
    "terms_and_conditions": "Uvjeti korištenja / zaštita podataka",
    "logout": "Odjava",
    "faq": "Često postavljana pitanja",
    "trainingmanager": "Zahtjevi",
    "trainingmanagerrequest": "Upit",
    "trainingmanagermanage": "Upravljanje",
    "trainingmanagermanagedetail": "Detalj"
  },
  "general": {
    "year": "Godina",
    "month": "Mjesec",
    "noResult": "Nije pronađen rezultat.",
    "months": {
      "10": "Listopad",
      "11": "Studeni",
      "12": "Prosinac",
      "01": "Siječanj",
      "02": "Veljača",
      "03": "Ožujak",
      "04": "Travanj",
      "05": "Svibanj",
      "06": "Lipanj",
      "07": "Srpanj",
      "08": "Kolovoz",
      "09": "Rujan"
    },
    "category": "Kategorija",
    "calendar": "Kalendar",
    "search": "Traži",
    "searchError": "Vnesite vsaj tri znake.",
    "showLess": "Prikaži manje",
    "showMore": "Prikaži više",
    'pagination': {
      'next': 'Sljedeći',
      'prev': 'Prethodni',
      'separator': 'od',
      'pageSize': 'Stavke po stranici'
    }
  },
  "maintenance": {
    "title": "Radovi održavanja",
    "text": "Zbog radova održavanja akademija trenutačno nije dostupna. Pokušajte ponovno kasnije."
  },
  "password": {
    "requirements": {
      "title": "Pravila za lozinku",
      "minimalLength": "Duljina mora biti najmanje #0.",
      "mandantName": "Ne smije se upotrebljavati pojam „#0“.",
      "names": "Lozinka ne smije biti jednaka imenu ili prezimenu.",
      "userName": "Lozinka ne smije sadržavati tvoja korisnička imena.",
      "userNameEqual": "Lozinka ne smije sadržavati tvoja korisnička imena.",
      "bigLetters": "Mora sadržavati najmanje #0 velika/velikih slova.",
      "smallLetters": "Mora sadržavati najmanje #0 mala/malih slova.",
      "digits": "Mora sadržavati najmanje #0 broja/brojeva slova.",
      "sameLetters": "Ne smije sadržavati uzastopno više od #0 znaka/znakova.",
      "oldPassword": "Ne smije biti stara lozinka.",
      "birthday": "Lozinka ne smije odgovarati tvom datumu rođenja.",
      "minSymbolsPolicy": "Mora sadržavati najmanje #0 posebna znaka / posebnih znakova.",
      "minPasswordLength": "Lozinka mora biti dugačka najmanje #0 znaka/znakova.",
      "maxPasswordAttempts": "Imate još #0 preostala/preostalih pokušaja za ispravan unos lozinke."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Žao nam je!",
      "text1": "Tražena stranica nije pronađena",
      "text2": "Upotrijebite tipku za povratak preglednika ili idite na početnu stranicu.",
      "button": "Natrag na početnu stranicu"
    },
    "userNotFound": {
      "headline": "Pri prijavi je došlo do pogreške!",
      "text": "Vaš korisnik organizacije nije pronađen. Pokušajte ponovno kasnije ili se obratite svojoj osobi za kontakt ili nadređenoj osobi.",
      "button": "Pokušaj ponovno"
    },
    "courseCalendar": {
      "expired": "Isteklo",
      "soonExpired": "Uskoro ističe"
    },
    "profile": {
      "userdata": "Korisnički podatci",
      "password": "Lozinka",
      "genderLab": "Oslovljavanje",
      "gender": {
        "men": "Gospodin",
        "woman": "Gđa",
        "diverse": "Razno",
        "unknown": "Nema unosa"
      },
      "firstname": "Ime",
      "lastname": "Prezime",
      "email": "Adresa e-pošte",
      "strasse": "Ulica/broj",
      "city": "Poštanski broj / mjesto",
      "phone": "Telefon",
      "fax": "Faks",
      "position": "Položaj",
      "department": "Odjel",
      "required": "Obvezno polje",
      "oldPassword": "Stara lozinka",
      "newPassword": "Nova lozinka",
      "newPasswordRep": "Ponavljanje",
      "company": "Tvrtka",
      "language": "Jezik"
    },
    "catalog": {
      "back": "Natrag",
      "bookingDialog": {
        "notice": "Ovdje unesite vaše obrazloženje upita za obuku"
      }
    },
    "contact": {
      "info": "Unesite svoj upit u polje za poruke. Ako se odnosite na posebnu obuku, navedite je: vaša se poruka tako može preusmjeriti na pravo mjesto.",
      "trainingSelection": "Odabir obuke",
      "yourMessage": "Vaša poruka",
      "success": "Vaša je poruka uspješno poslana!",
      "otherReason": "Općeniti upit za kontakt",
      "otherReasonSubject": "Ostali upiti",
      "chooseReason": "Odaberite"
    },
    "learningProfile": {
      "title": "Profil učenja",
      "unit": "Lekcije",
      "points": "Ukupan broj bodova",
      "history": "Povijest učenja",
      "restoreFilter": "Poništavanje filtra",
      "certificate": "Certifikat",
      "downloadAll": "Preuzmi sve",
      "withoutCategory": "Bez kategorije",
      "tooltip01": "Dovršili ste",
      "tooltip02": "od",
      "tooltip03": "lekcija.",
      "gamification": {
        "badge": {
          "title": "Priznanja",
          "congratulations": "Čestitamo!",
          "achieved": "Dobili ste novo priznanje!"
        }
      }
    },
    "courseDetail": {
      "title": "Detalji o obuci",
      "back": "Natrag na popis",
      "progress": "Napredak",
      "expiry": "vrijedi do",
      "classroomNo": "Broj osposobljavanja",
      "contactPerson": "Osoba za kontakt",
      "targetGroup": "Ciljna skupina",
      "bonusPoints": "Bonus bodovi",
      "classroomContent": "Sadržaji osposobljavanja",
      "webinarLight": "Informacije o webinaru",
      "specials": "Posebnosti",
      "stornoConditions": "Uvjeti storniranja",
      "meeting": "Termini",
      'freeDay': 'Free day',
      "speaker": "Voditelj obuke / referent",
      "location": "Lokacija osposobljavanja",
      "requested": "ZAHTJEV POSLAN",
      "waitlist": "LISTA ČEKANJA",
      "fullyBooked": "POPUNJENO",
      "edit": "Obradi",
      "webinarSpeaker": "Referent",
      "docsAndLinks": "Dokumenti i poveznice",
      "links": "Poveznice",
      "deadline": "Mjesto za rezervaciju",
      "webinar": {
        "host": "Otvori webinar",
        "state": {
          "ended": "Webinar je završen",
          "beforeStart": "Webinar počinje "
        }
      },
      "sidebar": {
        "price": "Cijena po osobi",
        "duration": "Trajanje učenja",
        "to": "do",
        "numberOfParticipants": "Broj sudionika",
        "status": "Status rezervacije",
        'totalStatus': 'cjelokupni status',
        'contentStatus': 'status obuke'
      },
      "bookingInformation": {
        "title": "Informacije o rezervaciji",
        "note": "Napomena",
        "accounts": "Troškovni centar",
        "invoiceRecipient": "Primatelj računa",
        "invoiceAddress": "Adresa za račun",
        "food": "Želje vezane za obroke",
        "arrival": "Dolazak",
        "arrivalType": {
          "car": "Auto",
          "train": "Vlak",
          "plane": "Zrakoplov"
        },
        "arrivalDate": "Datum dolaska",
        "departureDate": "Datum odlaska",
        "flightNumber": "Broj leta",
        "railwayStation": "Željeznički kolodvor"
      },
      "reserve": {
        "title": "Ne možete uskladiti termin/termine?",
        "text": "Predbilježite svoje interese! Obavijestit ćemo vas o mogućim novim terminima.",
        "button": "Predbilježi se",
        "prebooked": {
          "text": "Predbilježili ste svoj interes za ovu obuku."
        }
      },
      'downloads': {
        'modalHeadline': 'Prenosi',
        'sidebarLink': 'Dokument(i)',
      }
    },
    "course": {
      "type": {
        "0": "Obuka putem interneta",
        "1": "Obuka putem interneta",
        "2": "Obuka putem interneta",
        "3": "Obuka putem interneta",
        "4": "Obuka putem interneta",
        "5": "Obuka putem interneta",
        "6": "Obuka uživo",
        "7": "Webinar",
        "-2": "Projekt",
        "undefined": "-"
      },
      "status": {
        "open": "Otvoreno",
        'sent': 'Sent',
        "passed": "Položeno",
        'sent': 'Osjetiti',
        "failed": "Nije položeno",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Isteklo",
        "booked": "Rezervirano",
        "started": "U obradi",
        "upcoming": "Predstoji",
        "running": "U tijeku",
        "completed": "Završeno",
        "notyet": "Još nije dostupno"
      },
      "notification": {
        "success1": "Obuku ste ",
        "success2": " položili!"
      },
      "statusLabel": {
        "training": "Obuka",
        'content': 'Sadržaj',
        "exam": "Ispit",
        'attendance': 'Attendance',
        "survey": "Ispitivanje",
        "efficacyControl": "Kontrola učinka"
      },
      "start": {
        "survey": "Pokreni ispitivanje",
        "exam": "Pokreni ispit",
        "efficacyControl": "Pokreni kontrolu učinka"
      },
      "prefix": {
        "category": "Kategorija",
        "state": "Status",
        "type": "Vrsta obuke"
      },
      "noAssigned": "Nisu vam dodijeljene obuke.",
      "noSolved": "Niste dovršili još ni jednu obuku. Počnite sada s prvom lekcijom u dijelu „Moje obuke“!",
      "noAssignedBookable": "Nisu vam dodijeljene obuke koje se mogu rezervirati.",
      "noResult": "Nisu pronađene obuke koje odgovaraju kriterijima.",
      "loadingFailed": "Zatraženi skupovi podataka nisu se mogli učitati zbog nepoznate greške!",
      "noResultInCategory": "Kategoriji još nisu dodijeljene obuke.",
      "meeting": {
        "noMeeting": "nema termina",
        "multipleMeetings": "različiti termini"
      },
      "project": {
        "courses": "Obuke"
      }
    },
    "login": {
      "invalidUser": "Nevažeća prijava. Korisničko ime / lozinka su neispravni!",
      "invalidPassword": "Nevažeća prijava. Imate još #0 preostala/preostalih pokušaja za ispravan unos lozinke.",
      "accountBlocked": "Vaš je korisnički račun blokiran. Obratite se odgovornom zaposleniku.",
      "error": "Korisničko ime i/ili lozinka su neispravni!",
      "empty": "Ispunite sva polja.",
      "back": "Natrag na prijavu",
      "description": "Navedite svoje korisničko ime ili adresu e-pošte za ponovno slanje pristupnih podataka.",
      "password-reset": {
        "description": "Odaberite novu lozinku za vaš korisnički račun.",
        "success": "Vaša je lozinka uspješno izmijenjena.",
        "error": "Navede se lozinke ne podudaraju."
      },
      "placeholder": " Korisničko ime / adresa e-pošte",
      "button": "Zatraži korisničke podatke",
      "success": "Vaš je upit uspješno poslan.",
      "errorForgot": "Nije pronađen korisnik koji odgovara navedenim kriterijima.",
      "errorForgotSecurityQuestion": "Odgovor na žalost nije točan!"
    },
    "registration": {
      "description": "Ako nemate podatke za prijavu, možete se jednostavno registrirati putem akcijskog koda vaše tvrtke. Nakon toga ćemo vam odmah poslati poruku e-pošte.",
      "actionCodeError": "Uneseni kod za registraciju nije važeći",
      "actionCodeLimitExceeded": "Potrošen je maksimalan broj mogućih registracija za uneseni akcijski kod.",
      "registerDescription": "Ispunite obrazac u nastavku. Vaše ćemo vam pristupne podatke za nekoliko minuta poslati e-poštom.",
      "title": "Registracija na akademiju",
      "required": "Obvezno polje*",
      "emailError": "Adresa e-pošte koju ste unijeli već je dodijeljena.",
      "emailErrorNotAllowed": "Ovom adresom e-pošte nije moguća registracija.",
      "send": "Pošalji",
      "completeMessage": "Uspješno ste se registrirali za akademiju!"
    },
    "dataProtection": {
      "accept": "Prihvati",
      "acceptText": "Da, prihvaćam uvjete korištenja / zaštitu podataka"
    },
    "securityQuestion": {
      "title": "Sigurnosno pitanje",
      "chooseQuestion": "Odaberite",
      "questionSelection": "Odaberite pitanje",
      "yourAnswer": "Unesite svoj odgovor",
      "description": "Odgovorite na sigurnosno pitanje i odaberite novu lozinku za vaš korisnički račun."
    },
    "faq": {
      "title": "Često postavljana pitanja"
    }
  },
  "sidebar": {
    "all": "Sve",
    "categories": "KATEGORIJE",
    "status": "STATUS",
    "courseType": "VRSTA OBUKE",
    "topics": "TEMATSKA PODRUČJA"
  },
  "placeholder": {
    "username": "Korisničko ime",
    "password": "Lozinka",
    "actionCode": "Akcijski kod"
  },
  "button": {
    "login": "PRIJAVI SE",
    "login-sso": "PRIJAVA NA AKTIVNI DIREKTORIJ",
    "send": "POŠALJI",
    "start_course": "POČETAK",
    "changePassword": "Promijeni lozinku",
    "reset": "Poništi",
    "save": "Spremi",
    "request": "Upit",
    "storno": "Storniraj",
    "canceled": "Storniraj termin",
    "close": "Zatvori",
    "booking": "Rezerviraj",
    "waitlist": "Lista čekanja",
    "start_webinar": "Pokreni webinar",
    "open_webinar": "Otvori webinar",
    'cancel': 'Otkazati',
    'confirm': 'Potvrdite'
  },
  "bookingState": {
    "0": "ZAHTJEV POSLAN",
    "1": "REZERVIRANO",
    "2": "LISTA ČEKANJA",
    "3": "STORNIRANO",
    "4": "STORNIRANO",
    "5": "ODBIJENO",
    "6": "PREDBILJEŽENO",
    "onlineTraining": {
      "0": "ZAHTJEV POSLAN",
      "1": "REZERVIRANO",
      "2": "ODBIJENO"
    }
  },
  "foodPreference": {
    "none": "Nema",
    "lactoseIntolerance": "Intolerancija na laktozu",
    "vegetarian": "Vegetarijanska prehrana",
    "vegan": "Veganska prehrana",
    "nutAllergy": "Alergija na orašaste plodove",
    "diabetic": "Dijabetes",
    "glutenFree": "Bezglutenska prehrana",
    "kosher": "Košer prehrana",
    "halal": "Halal prehrana",
    "other": "Ostalo"
  },
  "notifications": {
    "error": "Nešto nije u redu!",
    "errors": {
      "missingInput": "Ispunite polja",
      "imageLoad": "Zatražena se slika nije mogla učitati"
    },
    "success": {
      "book": {
        "meeting": "Uspješno ste rezervirali obuku uživo.",
        "webinar": "Uspješno ste rezervirali webinar.",
        "course": "Uspješno ste rezervirali obuku.",
        "project": "Uspješno ste rezervirali projekt."
      },
      "request": {
        "meeting": "Uspješno ste poslali upit za obuku uživo.",
        "webinar": "Uspješno ste poslali upit za webinar.",
        "course": "Uspješno ste poslali upit za obuku.",
        "project": "Uspješno ste poslali upit za projekt."
      },
      "profile": {
        "updateSuccess": "Uspješno ste ažurirali svoj profil!",
        "updatePasswordSuccess": "Uspješno ste ažurirali svoju lozinku!"
      },
      "securityQuestion": {
        "questionSave": "Vaše je sigurnosno pitanje spremljeno!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Rezervacija već postoji!",
      "noaccess": "Pristup nije dozvoljen.",
      "notimplementet": "Funkcija trenutačno nije dostupna."
    },
    "success": {
      "booking": "Uspješno ste rezervirali.",
      "request": "Uspješno ste poslali upit.",
      "cancel": "Uspješno ste stornirali. ",
      "waitlist": {
        "add": "Stavljeni ste na listu čekanja"
      },
      "reservelist": {
        "add": "Stavljeni ste na popis s predbilježbama"
      }
    },
    "courseCalendar": {
      "noDate": "Bez roka"
    }
  },
  "trainingmanager": {
    "headline": "Upit za osposobljavanjem",
    "headlinerequest": "Podnesi zahtjev",
    "bottonrequest": "Podnesi zahtjev",
    "buttonmanage": "Upravljaj zahtjevima",
    "requestform": {
      "requester": "Podnositelj zahtjeva:",
      "date": "Datum:",
      "tab01": "Pošalji upit",
      "tab02": "Upit za skupinu / treće osobe",
      "tab01headline01": "Ime sudionika:",
      "tab01headline02": "Tema osposobljavanja:",
      "tab01headline04": "Ime sudionika:",
      "tab01headline03": "Detalji o tečaju:",
      "tab01label01": "Oslovljavanje:",
      "tab01label02": "Ime:",
      "tab01label03": "Prezime:",
      "tab01label04": "Gospodin",
      "tab01label05": "Gđa",
      "tab01label06": "Kategorija:",
      "tab01label07": "Naslov*:",
      "tab01label08": "Kratak opis:",
      "tab01label09": "Ostalo:",
      "tab01label09O": "Ostalo",
      "tab01label10": "Troškovni centar:",
      "tab01label11": "Termin (od/do):*",
      "tab01label12": "Broj tečaja:",
      "tab01label13": "Cijena:",
      "tab01label14": "Ponuđač:",
      "tab01label15": "Prilog:",
      "tab01label16": "Odaberi datoteku",
      "tab01label17": "Nije odabrana ni jedna datoteka...",
      "tab01label18": "Pošalji zahtjev",
      "tab01label19": "Dodaj dodatnu osobu",
      "tab01label20": "Prekini",
      "olheadline": "Dodaj sudionika",
      "olheadline1": "Ime sudionika",
      "olbodytext": "Naknadno odaberite imena dodatnih sudionika obuke.",
      "olerror": "Nisu pronađen sudionici. Promijenite parametre pretraživanja."
    },
    "manage": {
      "headline": "Upravljaj zahtjevima",
      "headlineInfo": "Upiti za zaposlenike",
      "bodytextInfo": "U nastavku ćete pronaći sve otvorene upite za osposobljavanjem koji se trebaju obraditi.",
      "newRequest": "Pošalji vlastiti zahtjev",
      "tableHeadline01": "Ulaz",
      "tableHeadline02": "Podnositelj zahtjeva",
      "tableHeadline03": "Br. tečaja",
      "tableHeadline04": "Naslov",
      "tableHeadline05": "Termin",
      "tableHeadline06": "Cijena",
      "tableMark": "Označi sve",
      "buttonapprove": "Daj dozvolu",
      "buttondecline": "Nemoj dati odobrenje",
      "modal": {
        "refuse": {
          "headline": "Poruka – odbij upit",
          "bodytext": "",
          "placeholder": "Unesite tekst...",
          "checkbox": "Odbij upit bez poruke",
          "button": "Pošalji",
          "approval": "Dana dozvola",
          "refuse": "Odobrenje nije dano"
        }
      }
    }
  }
}
