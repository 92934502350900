module.exports = {
  'main_navigation': {
    'login': 'Вход',
    'registration': 'Регистрация',
    'courses': 'Мои тренинги',
    'coursesDetail': 'Детали',
    'catalog': 'Каталог',
    'learningProfile': 'Профиль обучения',
    'contact': 'Контактная информация',
    'profile': 'Профиль',
    'pwLost': 'Забыли данные для входа?',
    'about': 'Сведения',
    'terms_and_conditions': 'Условия использования',
    'logout': 'Выход',
    'trainingmanager': 'Запросы',
    'trainingmanagerrequest': 'Запрос',
    'trainingmanagermanage': 'Управление',
    'trainingmanagermanagedetail': 'Детали',
    'faq': 'FAQ'
  },
  'general': {
    'year': 'Год',
    'month': 'Месяц',
    'months': {
      '10': 'Октябрь',
      '11': 'Ноябрь',
      '12': 'Декабрь',
      '01': 'Январь',
      '02': 'Февраль',
      '03': 'Март',
      '04': 'Апрель',
      '05': 'Май',
      '06': 'Июнь',
      '07': 'Июль',
      '08': 'Август',
      '09': 'Сентябрь'
    },
    'category': 'Категория',
    'calendar': 'Календарь',
    "search": "Поиск",
    "searchError": "Пожалуйста, введите не менее трех символов.",
    'showLess': 'Показать меньше',
    'showMore': 'Показать больше',
    'pagination': {
      'next': 'Следующий',
      'prev': 'Предыдущий',
      'separator': 'из',
      'pageSize': 'Элементы на странице'
    }
  },
  'maintenance': {
    'title': 'Техническое обслуживание',
    'text': 'Из-за ремонтных работ Академия в настоящее время недоступна. Пожалуйста, повторите попытку позже.'
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  'pages': {
    'notFound': {
      'headline': 'Мы сожалеем!',
      'text1': 'Запрашиваемая страница не может быть найдена.',
      'text2': 'Используйте кнопку возврата в браузере или вернитесь на главную страницу.',
      'button': 'Вернуться на главную'
    },
    'courseCalendar': {
      'expired': 'Истёк срок действия',
      'soonExpired': 'Скоро истекает'
    },
    'profile': {
      'userdata': 'Пользовательская информация',
      'password': 'Пароль',
      'genderLab': 'Приветствие',
      'gender': {
        'men': 'Г-н',
        'woman': 'Г-жа',
        'diverse': 'Иное',
        'unknown': 'Не указано'
      },
      'firstname': 'Имя',
      'lastname': 'Фамилия',
      'email': 'E-mail',
      'strasse': 'Адрес',
      'city': 'Индекс / Город',
      'phone': 'Телефон',
      'fax': 'Факс',
      'position': 'Должность',
      'department': 'Департамент',
      'required': 'Обязательное поле',
      'oldPassword': 'Старый пароль',
      'newPassword': 'Новый пароль',
      'newPasswordRep': 'Повторить пароль',
      'company': 'Компания',
      'language': 'Язык'
    },
    'catalog': {
      'back': 'Назад',
      'bookingDialog': {
        'notice': 'Пожалуйста, заполните Ваше объяснение по запросу'
      }
    },
    'contact': {
      'info': 'Пожалуйста, введите Ваш запрос в поле сообщения. Если Вы обращаетесь по тренингу, пожалуйста, укажите его. После этого Ваше сообщение может быть переадресовано ',
      'trainingSelection': 'Выбор тренинга',
      'yourMessage': 'Ваше сообщение',
      'success': 'Ваше сообщение успешно отправлено!',
      'otherReason': 'Общий запрос',
      'otherReasonSubject': 'Иной запрос',
      'chooseReason': 'Пожалуйста, выберите'
    },
    'learningProfile': {
      'title': 'Профиль обучения',
      'unit': 'Раздел обучения',
      'points': 'Всего баллов',
      'history': 'История обучения',
      'restoreFilter': 'Сбросить фильтр',
      'certificate': 'Сертификат',
      'downloadAll': 'Скачать все',
      'withoutCategory': 'Без категории',
      'tooltip01': 'У вас есть',
      'tooltip02': 'от',
      'tooltip03': 'раздел обучения завершен.',
      'gamification': {
        'badge':{
          'title': 'Награды',
          'congratulations': 'Поздравляем!',
          'achieved': 'Вы получили новую награду!'
        }
      }
    },
    'courseDetail': {
      'title': 'Детали',
      'back': 'Назад к списку тренингов',
      'progress': 'Прогресс',
      'expiry': 'действительно до',
      'classroomNo': 'Номер тренинга',
      'contactPerson': 'Контактное лицо',
      'targetGroup': 'Целевая группа',
      'bonusPoints': 'Бонусные баллы',
      'classroomContent': 'Содержание тренинга',
      'webinarLight': 'Инфомация по вебинару',
      'specials': 'Примечания',
      'stornoConditions': 'Политика по отменам',
      'meeting': 'Даты',
      'freeDay': 'Free day',
      'speaker': 'Тренер / Спикер',
      'location': 'Локация',
      'requested': 'ЗАПРОШЕНО',
      'waitlist': 'ЛИСТ ОЖИДАНИЯ',
      'fullyBooked': 'НЕТ МЕСТ',
      'edit': 'Редактировать',
      'webinarSpeaker': 'Спикер',
      'docsAndLinks': 'Документы и ссылки',
      'links': 'интернет-ссылка',
      'webinar': {
        'host': 'Открытые вебинары',
        'state': {
          'ended': 'Вебинар завершен',
          'beforeStart': 'Вебинар начинается в '
        }
      },
      'sidebar': {
        'price': 'Цена p. P. ',
        'duration': 'Время обучения',
        'to': 'к',
        'numberOfParticipants': 'Количество участников',
        'status': 'Статус записи',
        'totalStatus': 'Общее состояние',
        'contentStatus': 'Статус обучения'
      },
      'bookingInformation': {
        'title': 'Информация по записи',
        'note': 'Примечание',
        'accounts': 'Кост-центр',
        'invoiceRecipient': 'Получатель счета',
        'invoiceAddress': 'Адрес выставления счета ',
        'food': 'Пищевые предпочтения',
        'arrival': 'Прибытие',
        'arrivalType': {
          'none': 'Не указано',
          'car': 'Авто',
          'train': 'Поезд',
          'plane': 'Самолет'
        },
        'arrivalDate': 'Дата прибытия',
        'departureDate': 'Дата отъезда',
        'flightNumber': 'Номер рейса',
        'railwayStation': 'Вокзал'
      },
      'reserve': {
        'title': 'Вы не можете присутствовать на встрече (-ах)?',
        'text': 'Отметьте ваш интерес! После этого Вы будете проинформированы о возможных новых датах.',
        'button': 'Зарезервировать',
        'prebooked': {
          'text': 'Ваш резерв на это мероприятие/тренинг был учтен.'
        }
      },
      'downloads': {
        'modalHeadline': 'Загрузки',
        'sidebarLink': 'Документ(ы)',
      }
    },
    'course': {
      'type': {
        '0': 'Онлайн-тренинг',
        '1': 'Онлайн-тренинг',
        '2': 'Онлайн-тренинг',
        '3': 'Онлайн-тренинг',
        '4': 'Онлайн-тренинг',
        '5': 'Онлайн-тренинг',
        '6': 'Обучение в классах',
        '7': 'Вебинар',
        '-2': 'Проект',
        'undefined': '-'
      },
      'status': {
        'open': 'Открыт',
        'sent': 'Sent',
        'passed': 'Завершен',
        'sent': 'Отправлено',
        'failed': 'Завершен неудачно',
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        'expired': 'Истёк',
        'booked': 'Записан',
        'started': 'В процессе',
        'upcoming': 'Предстоящие',
        'running': 'Текущие',
        'completed': 'Завершенные'
      },
      'notification': {
        'success1': 'Вы закончили обучение',
        'success2': ' успешно!'
      },
      'statusLabel': {
        'training': 'Тренинг',
        'content': 'Содержание',
        'attendance': 'Attendance',
        'exam': 'Экзамен',
        'survey': 'Обратная связь'
      },
      'start': {
        'survey': 'Начать опрос',
        'exam': 'Начать экзамен'
      },
      'prefix': {
        'category': 'Категория',
        'state': 'Статус',
        'type': 'Тип тренинга'
      },
      'noAssigned': 'Вам не назначены тренинги.',
      'noSolved': '"Вы еще не закончили ни одного тренинга. Начните обучение в разделе ""Мои тренинги""!"',
      'noAssignedBookable': 'Тренинги недоступны для записи.',
      'noResult': 'Не найдено тренингов, соответствующих критериям.',
      'loadingFailed': 'Запрашиваемые данные не могут быть загружены из-за неизвестной ошибки!',
      'noResultInCategory': 'На эту категорию не назначены тренинги.',
      'meeting': {
        'noMeeting': 'нет даты',
        'multipleMeetings': 'Различные даты'
      },
      'project': {
        'courses': 'Тренинги'
      }
    },
    'login': {
      'invalidUser': 'Invalid login data. Username / Password is wrong!',
      'invalidPassword': 'Invalid login data. You have #0 more attempts to enter the password correctly.',
      'accountBlocked': 'Your account has been blocked, please contact a responsible employee.',
      'error': 'Имя пользователя и/или пароль неверны!',
      'empty': 'Пожалуйста, заполните все обязательные поля.',
      'back': 'Назад ко входу в систему',
      'description': 'Пожалуйста, введите Ваше имя пользователя или адрес электронной почты, чтобы получить Ваши данные для входа в систему.',
      'password-reset': {
        'description': 'Пожалуйста, выберите новый пароль для Вашей учетной записи.',
        'success': 'Ваш пароль был успешно изменен.',
        'error': 'Пароли не совпадают.'
      },
      'placeholder': ' Имя пользователя / адрес электронной почты',
      'button': 'Запросить регистрационные данные',
      'success': 'Запрос успешно отправлен.',
      'errorForgot': 'Не найден пользователь, соответствующий критериям!',
      'errorForgotSecurityQuestion': 'Ответ, который вы ввели, не верный!'
    },
    'registration': {
      'description': 'Если у вас еще нет аккаунта, вы можете зарегистрироваться через промокод вашей компании. После этого Вы получите письмо по электронной почте.',
      'actionCodeError': 'Недействительный регистрационный код',
      'actionCodeLimitExceeded': 'Количество возможных регистраций для введенного кода превышено.',
      'registerDescription': 'Пожалуйста, заполните форму. В ближайшее время Вы получите Ваши данные для входа в систему по электронной почте.',
      'title': 'Регистрация',
      'required': 'Обязательное поле *',
      'emailError': 'Этот адрес электронной почты уже используется.',
      'send': 'Отправить',
      'completeMessage': 'Вы успешно зарегистрировались!'
    },
    'dataProtection': {
      'accept': 'Согласиться',
      'acceptText': 'Да, я согласен с условиями использования.'
    },
    'securityQuestion': {
      'title': 'Вопрос безопасности',
      'chooseQuestion': 'Пожалуйста, выберите',
      'questionSelection': 'Пожалуйста, выберите один вопрос',
      'yourAnswer': 'Пожалуйста, введите ваш ответ',
      'description': 'Пожалуйста, ответьте на вопрос о безопасности и выберите новый пароль для вашей учетной записи пользователя.'
    },
    'faq': {
      'title': 'Часто задаваемые вопросы'
    }
  },
  'sidebar': {
    'all': 'Все',
    'categories': 'КАТЕГОРИИ',
    'status': 'СТАТУС',
    'courseType': 'ТИП ТРЕНИНГА'
  },
  'placeholder': {
    'username': 'Имя пользователя',
    'password': 'Пароль',
    'actionCode': 'Промокод'
  },
  'button': {
    'login': 'ВОЙТИ',
    'login-sso': 'ЛОГИН АКТИВНОЙ ДИРЕКТОРИИ',
    'send': 'ОТПРАВИТЬ',
    'start_course': 'СТАРТ',
    'changePassword': 'Изменить пароль',
    'reset': 'Сброс',
    'save': 'Сохранить',
    'request': 'Запрос',
    'storno': 'Отменить',
    'canceled': 'Назначение отменено',
    'close': 'Закрыть',
    'booking': 'Записаться',
    'waitlist': 'Список ожидания',
    'start_webinar': 'Стартовый вебинар',
    'open_webinar': 'Открытый вебинар',
    'cancel': 'Отмена',
    'confirm': 'Подтвердить'
  },
  'bookingState': {
    '0': 'ЗАПРОШЕНО',
    '1': 'ЗАПИСАН',
    '2': 'ЛИСТ ОЖИДАНИЯ',
    '3': 'ОТМЕНЁН',
    '4': 'ОТМЕНЁН',
    '5': 'ОТКАЗАНО',
    '6': 'ОТМЕНЕНА ДАТЫ',
    'onlineTraining': {
      '0': 'ЗАПРОШЕНО',
      '1': 'ЗАПИСАН',
      '2': 'ОТКАЗАНО'
    }
  },
  'foodPreference': {
    'none': 'Нет',
    'lactoseIntolerance': 'Непереносимость лактозы',
    'vegetarian': 'Вегетарианский',
    'vegan': 'Веган',
    'nutAllergy': 'Аллергия на орехи',
    'diabetic': 'Диабет',
    'glutenFree': 'Без глютена',
    'kosher': 'Кошер',
    'halal': 'Халяль',
    'other': 'Другое'
  },
  'notifications': {
    'error': 'Что-то пошло не так!',
    'errors': {
      'missingInput': 'Пожалуйста, заполните поля',
      'imageLoad': 'Запрашиваемое изображение не могло быть загружено.'
    },
    'success': {
      'book': {
        'meeting': 'Вы успешно забронировали обучение в классе.',
        'webinar': 'Вы успешно забронировали вебинар.',
        'course': 'Вы успешно забронировали обучение.',
        'project': 'Вы успешно забронировали проект.'
      },
      'request': {
        'meeting': 'Вы успешно отправили запрос на бронирование обучения в классе.',
        'webinar': 'Вы успешно отправили запрос на проведение вебинара.',
        'course': 'Вы успешно отправили запрос на бронирование тренинга.',
        'project': 'Вы успешно отправили запрос на бронирование проекта.'
      },
      'profile': {
        'updateSuccess': 'Вы успешно обновили свой профиль!',
        'updatePasswordSuccess': 'Вы успешно обновили свой пароль!'
      },
      'securityQuestion': {
        'questionSave': 'Ваш вопрос безопасности сохранен!'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': 'Бронирование уже существует!',
      'noaccess': 'Доступ не разрешен.',
      'notimplementet': 'На данный момент функция недоступна.'
    },
    'success': {
      'booking': 'Вы успешно забронировали.',
      'request': 'Вы успешно отправили запрос на бронирование.',
      'cancel': 'Вы успешно отменили. ',
      'waitlist': {
        'add': 'Вы были в списке ожидающих'
      },
      'reservelist': {
        'add': 'Вы были в списке'
      }
    },
    'courseCalendar': {
      'noDate': 'Без конечного срока'
    }
  },
  'trainingmanager': {
    'headline': 'Запрос на дальнейшее обучение',
    'headlinerequest': 'Отправить запрос',
    'bottonrequest': 'Отправить запрос',
    'buttonmanage': 'Управление запросами',
    'requestform': {
      'requester': 'Заявитель:',
      'date': 'Дата:',
      'tab01': 'Отправить запрос',
      'tab02': 'Групповой запрос / запрос третьей стороны',
      'tab01headline01': 'Имя участника:',
      'tab01headline02': 'Тема тренинга:',
      'tab01headline04': 'Имена участников:',
      'tab01headline03': 'Информация о курсе:',
      'tab01label01': 'Приветствие:',
      'tab01label02': 'Имя:',
      'tab01label03': 'Фамилия:',
      'tab01label04': 'Г-н',
      'tab01label05': 'Г-жа',
      'tab01label06': 'Категория:',
      'tab01label07': 'Название*:',
      'tab01label08': 'Краткое описание:',
      'tab01label09': 'Другое:',
      'tab01label09O': 'Другое',
      'tab01label10': 'Центр затрат:',
      'tab01label11': 'Дата (с/по):*',
      'tab01label12': 'Номер курса:',
      'tab01label13': 'Цена:',
      'tab01label14': 'Провайдер:',
      'tab01label15': 'Приложение:',
      'tab01label16': 'Выберите файл',
      'tab01label17': 'Файл не выбран...',
      'tab01label18': 'Отправить запрос',
      'tab01label19': 'Добавить человека',
      'tab01label20': 'Отменить',
      'olheadline': 'Добавить участника',
      'olheadline1': 'Имя участника',
      'olbodytext': 'Пожалуйста, выберите других участников курса ниже.',
      'olerror': 'Участники не найдены. Пожалуйста, измените параметры поиска.'
    },
    'manage': {
      'headline': 'Управление запросами',
      'headlineInfo': 'Команда запрашивает ',
      'bodytextInfo': 'Ниже вы найдете все открытые запросы на обучение на обработку.',
      'newRequest': 'Отправить собственный запрос',
      'tableHeadline01': '',
      'tableHeadline02': 'Заявитель',
      'tableHeadline03': 'Номер курса',
      'tableHeadline04': 'Название',
      'tableHeadline05': 'Дата',
      'tableHeadline06': 'Цена',
      'tableMark': 'Выберите все',
      'buttonapprove': 'Подтвердить',
      'buttondecline': 'Сокращение',
      'modal': {
        'refuse': {
          'headline': 'Сообщение - Запрос о снижении',
          'bodytext': '',
          'placeholder': 'Пожалуйста, введите текст...',
          'checkbox': 'Сокращение без сообщения',
          'button': 'Отправить',
          'approval': 'Запрос подтвержден',
          'refuse': 'Запрос отклонен'
        }
      }
    }
  }
};
