module.exports = {
  'main_navigation': {
    'registration': '登録',
    'courses': 'マイトレーニング',
    'coursesDetail': 'トレーニング詳細',
    'catalog': 'カタログ',
    'learningProfile': '受講プロファイル',
    'contact': 'コンタクト',
    'profile': 'プロファイル',
    'pwLost': 'ログインデータをお忘れですか？',
    'about': 'インプリント',
    'terms_and_conditions': '利用条件',
    'login': 'ログイン',
    'logout': 'ログアウト',
    'trainingmanager': 'リクエスト',
    'trainingmanagerrequest': 'リクエスト',
    'trainingmanagermanage': '管理',
    'trainingmanagermanagedetail': '詳細',
    'faq': 'よくある質問'
  },
  'general': {
    'year': '年',
    'month': '月',
    'months': {
      '10': '10月',
      '11': '11月',
      '12': '12月',
      '01': '1月',
      '02': '2月',
      '03': '3月',
      '04': '4月',
      '05': '5月',
      '06': '6月',
      '07': '7月',
      '08': '8月',
      '09': '9月'
    },
    'category': 'カテゴリー',
    'calendar': 'カレンダー',
    'search': '検索',
    "searchError": "3文字以上入力してください。",
    'showLess': '表示を減らす',
    'showMore': 'さらに表示',
    'pagination': {
      'next': '次のページ',
      'prev': '前',
      'separator': '/',
      'pageSize': 'ページごとの項目'
    }
  },
  'maintenance': {
    'title': 'メンテナンス',
    'text': 'メンテナンス作業中のため現在アカデミーはご利用いただけません。お手数ですが後ほどお試しください。'
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  'pages': {
    'notFound': {
      'headline': '誠に恐れ入りますが',
      'text1': 'お探しのページは見つかりませんでした。',
      'text2': 'ブラウザの戻るボタンをクリックするか、ホームに戻ってください。',
      'button': 'ホームに戻る'
    },
    'courseCalendar': {
      'expired': '有効期限切れ',
      'soonExpired': 'まもなく有効期限が切れます'
    },
    'profile': {
      'userdata': 'ユーザー情報',
      'password': 'パスワード',
      'genderLab': '',
      'gender': {
        'men': '様',
        'woman': '様',
        'diverse': '',
        'unknown': '選択されていません'
      },
      'firstname': '名',
      'lastname': '姓',
      'email': 'eメール',
      'strasse': '住所',
      'city': '郵便番号/市町村',
      'phone': '電話',
      'fax': 'Fax',
      'position': 'ポジション',
      'department': '部門',
      'required': '必須入力フィールド',
      'oldPassword': '以前のパスワード',
      'newPassword': '新しいパスワード',
      'newPasswordRep': 'パスワードを再度入力してください',
      'company': '会社',
      'language': '言語'
    },
    'catalog': {
      'back': '戻る',
      'bookingDialog': {
        'notice': 'ご依頼内容をご記入ください'
      }
    },
    'contact': {
      'info': 'メッセージ欄にご要望をご記入ください。トレーニングについてのお問い合わせは担当部門に転送いたします。',
      'trainingSelection': 'トレーニングの選択',
      'yourMessage': 'あなたのメッセージ',
      'success': 'あなたのメッセージは送信されました！',
      'otherReason': '一般的なお問い合わせ',
      'otherReasonSubject': 'その他のリクエスト',
      'chooseReason': 'お選びください'
    },
    'learningProfile': {
      'title': '受講プロフィール',
      'unit': '受講プログラム',
      'points': '合計ポイント',
      'history': '受講履歴',
      'restoreFilter': 'フィルターのリセット',
      'certificate': '証明書',
      'downloadAll': '一括ダウンロード',
      'withoutCategory': 'カテゴリなし',
      'tooltip01': 'あなたは',
      'tooltip02': 'の',
      'tooltip03': '受講プログラムを修了しました',
      'gamification': {
        'badge':{
          'title': 'アワード',
          'congratulations': 'おめでとうございます!',
          'achieved': '新しい賞を受賞しました!'
        }
      }
    },
    'courseDetail': {
      'title': 'トレーニング詳細',
      'back': 'トレーニングリストに戻る',
      'progress': '進捗',
      'expiry': '有効期限',
      'classroomNo': 'トレーニング番号',
      'contactPerson': '担当者',
      'targetGroup': '対象者',
      'bonusPoints': 'ボーナスポイント',
      'classroomContent': 'トレーニング内容',
      'webinarLight': 'ウェビナー情報',
      'specials': '特色',
      'stornoConditions': '取り消しについての規約',
      'meeting': '日付',
      'freeDay': 'Free day',
      'speaker': 'トレーナー/スピーカー',
      'location': 'トレーニング実施場所',
      'requested': 'リクエスト済',
      'waitlist': '順番待ちリスト',
      'fullyBooked': '予定数終了',
      'edit': '編集',
      'webinarSpeaker': 'スピーカー',
      'docsAndLinks': '資料・リンク集',
      'links': 'リンク集',
      'webinar': {
        'host': 'オープンウェビナー',
        'state': {
          'ended': 'ウェビナーの終わり',
          'beforeStart': 'ウェビナー開始時間'
        }
      },
      'sidebar': {
        'price': '価格',
        'duration': '受講時間',
        'to': 'に',
        'numberOfParticipants': '参加者人数',
        'status': '予約状況',
        'totalStatus': '総合的な状況',
        'contentStatus': 'トレーニング状況'
      },
      'bookingInformation': {
        'title': '予約情報',
        'note': '備考',
        'accounts': 'コストセンター',
        'invoiceRecipient': '請求先',
        'invoiceAddress': '請求先住所',
        'food': '食事の選択',
        'arrival': '到着',
        'arrivalType': {
          'none': '選択されていません',
          'car': '車',
          'train': '列車',
          'plane': '飛行機'
        },
        'arrivalDate': '到着日',
        'departureDate': '出発日',
        'flightNumber': 'フライトナンバー',
        'railwayStation': '鉄道駅'
      },
      'reserve': {
        'title': '予約の時間に出席できませんか？',
        'text': 'ご興味のあることをお知らせください。追って新しい日程をお知らせします。',
        'button': '予約する',
        'prebooked': {
          'text': 'このイベント/トレーニングのご予約を確認いたしました'
        }
      },
      'downloads': {
        'modalHeadline': 'ダウンロード',
        'sidebarLink': 'ドキュメント（英語）',
      }
    },
    'course': {
      'type': {
        '0': 'オンライントレーニング',
        '1': 'オンライントレーニング',
        '2': 'オンライントレーニング',
        '3': 'オンライントレーニング',
        '4': 'オンライントレーニング',
        '5': 'オンライントレーニング',
        '6': 'クラスルームトレーニング',
        '7': 'ウェブセミナー',
        '-2': 'プロジェクト',
        'undefined': ' - '
      },
      'status': {
        'open': 'オープン',
        'sent': 'Sent',
        'passed': '修了',
        'sent': '送信',
        'failed': '未修了',
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        'expired': '期限切れ',
        'booked': '予約済',
        'started': '進行中',
        'upcoming': '今後の予定',
        'running': '実施中',
        'completed': '完了'
      },
      'notification': {
        'success1': 'トレーニングを完了しました',
        'success2': '成功しました！'
      },
      'statusLabel': {
        'training': 'トレーニング',
        'content': 'コンテンツ',
        'attendance': 'Attendance',
        'exam': 'テスト',
        'survey': 'フィードバック'
      },
      'start': {
        'survey': 'サーベイ開始',
        'exam': 'テスト開始'
      },
      'prefix': {
        'category': 'カテゴリー',
        'state': 'ステータス',
        'type': 'トレーニングの種類'
      },
      'noAssigned': '未受講の項目はありません。',
      'noSolved': 'まだトレーニングを終えていません。「マイトレーニング」エリアから未受講のプログラムを今すぐ始めましょう!',
      'noAssignedBookable': '受講すべきプログラムはありません',
      'noResult': 'ご希望条件に一致するプログラムが見つかりませんでした',
      'loadingFailed': 'エラーが発生したため送信できませんでした',
      'noResultInCategory': 'このカテゴリに該当するプログラムはありません',
      'meeting': {
        'noMeeting': '日付なし',
        'multipleMeetings': 'その他の日程'
      },
      'project': {
        'courses': 'トレーニング'
      }
    },
    'login': {
      'invalidUser': 'Invalid login data. Username / Password is wrong!',
      'invalidPassword': 'Invalid login data. You have #0 more attempts to enter the password correctly.',
      'accountBlocked': 'Your account has been blocked, please contact a responsible employee.',
      'error': 'ユーザー名、パスワードが間違っています',
      'empty': 'すべての項目を入力してください。',
      'back': 'ログインに戻る',
      'description': 'ログイン情報を受取るためにユーザー名またはe-mailアドレスを入力してください',
      'password-reset': {
        'description': 'ユーザーアカウントの新しいパスワードを入力してください',
        'success': 'パスワードが正常に変更されました',
        'error': 'パスワードが一致しません'
      },
      'placeholder': 'ユーザー名/メールアドレス',
      'button': 'ログインデータをリクエストする',
      'success': 'リクエストが正常に送信されました',
      'errorForgot': '条件に一致するユーザーが見つかりませんでした！',
      'errorForgotSecurityQuestion': '入力した内容に誤りがあります'
    },
    'registration': {
      'description': 'まだアカウントをお持ちでない方は、プロモーションコードから登録してください。登録後に通知メールが届きます',
      'actionCodeError': '登録コードが無効です',
      'actionCodeLimitExceeded': '入力された登録コードが最大値を超えています',
      'registerDescription': 'フォームに記入してください。ログインデータをe-mailで通知いたします',
      'title': '登録',
      'required': '必須入力フィールド',
      'emailError': 'このメールアドレスは既に使用されています',
      'send': '提出する',
      'completeMessage': '登録が完了いたしました！'
    },
    'dataProtection': {
      'accept': '同意する',
      'acceptText': 'はい、利用規約に同意します'
    },
    'securityQuestion': {
      'title': '秘密の質問',
      'chooseQuestion': '選んでください',
      'questionSelection': '一つの質問を選択してください',
      'yourAnswer': '答えを入力してください',
      'description': 'セキュリティの質問に回答し、ユーザーアカウントの新しいパスワードを選択してください'
    },
    'faq': {
      'title': 'よくある質問'
    }
  },
  'sidebar': {
    'all': 'すべて',
    'categories': 'カテゴリー',
    'status': 'ステータス',
    'courseType': 'トレーニングタイプ'
  },
  'placeholder': {
    'username': 'ユーザー名',
    'password': 'パスワード',
    'actionCode': 'プロモーションコード'
  },
  'button': {
    'login': 'サインイン',
    'login-sso': '直接ログイン',
    'send': '送信',
    'start_course': '開始する',
    'changePassword': 'パスワードを変更する',
    'reset': 'リセット',
    'save': '保存する',
    'request': 'お問い合わせ',
    'storno': 'キャンセル',
    'canceled': '予約のキャンセル',
    'close': '閉じる',
    'booking': '予約',
    'waitlist': 'キャンセル待ち',
    'start_webinar': 'ウェビナー開始',
    'open_webinar': 'オープンウェビナー',
    'cancel': 'キャンセル',
    'confirm': '確認'
  },
  'bookingState': {
    '0': 'リクエスト済',
    '1': '予約済',
    '2': '予約待ちリスト',
    '3': 'キャンセル済',
    '4': 'キャンセル済',
    '5': '却下済',
    '6': '保存日',
    'onlineTraining': {
      '0': 'リクエスト済',
      '1': '予約済',
      '2': '却下済'
    }
  },
  'foodPreference': {
    'none': 'なし',
    'lactoseIntolerance': '乳糖不耐症',
    'vegetarian': 'ベジタリアン',
    'vegan': 'ビーガン',
    'nutAllergy': 'ナッツアレルギー',
    'diabetic': '糖尿病',
    'glutenFree': 'グルテンフリー',
    'kosher': 'コーシャー',
    'halal': 'ハラール',
    'other': 'その他'
  },
  'notifications': {
    'error': '何かが間違っています！',
    'errors': {
      'missingInput': 'フィールドに記入してください',
      'imageLoad': '要求された画像をロードできませんでした'
    },
    'success': {
      'book': {
        'meeting': 'クラストレーニングを予約しました',
        'webinar': 'ウェビナーを予約しました',
        'course': 'トレーニングの予約を受け付けました',
        'project': 'あなたのプロジェクト予約を受け付けました'
      },
      'request': {
        'meeting': 'クラストレーニングの予約リクエストを送信しました',
        'webinar': 'ウェビナー予約のリクエストを送信しました',
        'course': 'トレーニング予約のリクエストを送信しました',
        'project': 'プロジェクト予約リクエストは正常に送信されました'
      },
      'profile': {
        'updateSuccess': 'プロフィールを更新しました！',
        'updatePasswordSuccess': 'パスワードを更新しました！'
      },
      'securityQuestion': {
        'questionSave': 'セキュリティのための質問を保存しました！'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': '既に予約済です！',
      'noaccess': 'アクセス権限がありません',
      'notimplementet': 'ただいまこの機能は利用できません'
    },
    'success': {
      'booking': '予約いたしました',
      'request': '予約リクエストを送りました',
      'cancel': 'キャンセルいたしました',
      'waitlist': {
        'add': '予約待ちリストに登録されています'
      },
      'reservelist': {
        'add': 'リストに登録されています'
      }
    },
    'courseCalendar': {
      'noDate': '期限なし'
    }
  },
  'trainingmanager': {
    'headline': '継続的なトレーニングの必要性',
    'headlinerequest': '申請',
    'bottonrequest': 'リクエストを送信',
    'buttonmanage': 'リクエストを管理',
    'requestform': {
      'requester': '依頼人',
      'date': '日付：',
      'tab01': 'リクエストを送信',
      'tab02': 'グループ/サードパーティの要求',
      'tab01headline01': '参加者のお名前：',
      'tab01headline02': 'トレーニングトピック：',
      'tab01headline04': '参加者のお名前：',
      'tab01headline03': 'コース詳細',
      'tab01label01': '挨拶：',
      'tab01label02': '名：',
      'tab01label03': '姓：',
      'tab01label04': '様',
      'tab01label05': '様',
      'tab01label06': 'カテゴリー：',
      'tab01label07': 'タイトル*：',
      'tab01label08': '簡単な説明：',
      'tab01label09': 'その他：',
      'tab01label09O': 'その他：',
      'tab01label10': 'コストセンター',
      'tab01label11': '日付（from/to）：*',
      'tab01label12': 'コース番号：',
      'tab01label13': '金額：',
      'tab01label14': 'プロバイダー：',
      'tab01label15': '添付ファイル：',
      'tab01label16': 'ファイルを選択',
      'tab01label17': 'ファイルが選択されていません...',
      'tab01label18': 'リクエストを送信',
      'tab01label19': '追加',
      'tab01label20': 'キャンセル',
      'olheadline': '参加者の追加',
      'olheadline1': '参加者の名前',
      'olbodytext': '下記より他のコースの参加者を選択してください',
      'olerror': '参加者が見つかりませんでした。検索パラメータを変更してください。'
    },
    'manage': {
      'headline': 'リクエストを管理',
      'headlineInfo': 'チームのリクエスト',
      'bodytextInfo': '以下よりリクエスト中のリクエストを検索できます',
      'newRequest': '自分のリクエストを送信',
      'tableHeadline01': '到着',
      'tableHeadline02': '依頼人',
      'tableHeadline03': 'コース番号',
      'tableHeadline04': 'タイトル',
      'tableHeadline05': '日付',
      'tableHeadline06': '金額',
      'tableMark': 'すべて選択',
      'buttonapprove': '承認',
      'buttondecline': '却下',
      'modal': {
        'refuse': {
          'headline': 'メッセージ -リクエストは拒否されました',
          'bodytext': '',
          'placeholder': 'テキストを入力してください...',
          'checkbox': 'メッセージなしで拒否する',
          'button': '提出する',
          'approval': 'リクエストは承認されました',
          'refuse': 'リクエストは拒否されました'
        }
      }
    }
  }
};
