import CustomizingLoader from '@/utils/CustomizingLoader';
import { lazyLoading } from '@/utils/helpers';
import Auth from "@/middleware/auth";

export const OpenCatalog = {
  path: '/',
  component: () => CustomizingLoader('views/layout/pages/LayoutCatalog'),
  meta: {
    label: 'main_navigation.catalog'
  },
  children: [{
    path: '',
    name: 'openCatalog',
    component: lazyLoading('catalog'),
    meta: {
      label: 'main_navigation.catalog'
    }
  },
  {
    path: '/category/:id/courses',
    name: 'openCourseCatalogDetail',
    component: lazyLoading('catalog', 'CatalogCourseList'),
    meta: {
      label: 'main_navigation.catalog'
    }
  },
  {
    path: '/course/:id',
    name: 'openCourseDetail',
    component: lazyLoading('courses', 'Detail'),
    meta: {
      label: 'main_navigation.coursesDetail'
    }
  },
  {
    path: '/password-lost',
    name: 'password-lost',
    showInMenu: false,
    component: lazyLoading('password-lost'),
    meta: {
      label: 'main_navigation.login'
    }
  }, {
    path: '/forgotPassword',
    name: 'forgotPassword',
    showInMenu: false,
    component: lazyLoading('password-lost', 'ResetPassword'),
    meta: {
      label: 'main_navigation.login'
    }
  }, {
    path: '/resetWithSecurityQuestion',
    name: 'resetWithSecurityQuestion',
    showInMenu: false,
    component: lazyLoading('password-lost',
      'ResetPasswordWithSecurityQuestion'),
    meta: {
      label: 'main_navigation.login'
    }
  }, {
      path: ':id/calendar',
      name: 'openCatalogCalendar',
      component: lazyLoading('catalog', 'Calendar')
  }]
};
