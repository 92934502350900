module.exports = {
  "main_navigation": {
    "login": "Prisijungimas",
    "registration": "Registracija",
    "courses": "Mano pratybos",
    "coursesDetail": "Pratybų informacija",
    "catalog": "Katalogas",
    "learningProfile": "Mokymosi profilis",
    "contact": "Kontaktas",
    "profile": "Profilis",
    "pwLost": "Pamiršote prisijungimo duomenis?",
    "about": "Autorių sąrašas",
    "terms_and_conditions": "Naudojimo sąlygos / Duomenų apsaugą",
    "logout": "Atsijungti",
    "faq": "FAQ",
    "trainingmanager": "Prašymai",
    "trainingmanagerrequest": "Prašymas",
    "trainingmanagermanage": "Valdyti",
    "trainingmanagermanagedetail": "Duomenys"
  },
  "general": {
    "year": "Metai",
    "month": "Mėnuo",
    "noResult": "Jokių rezultatų nerasta.",
    "months": {
      "10": "Spalis",
      "11": "Lapkritis",
      "12": "Gruodis",
      "01": "Sausis",
      "02": "Vasaris",
      "03": "Kovas",
      "04": "Balandis",
      "05": "Gegužė",
      "06": "Birželis",
      "07": "Liepa",
      "08": "Rugpjūtis",
      "09": "Rugsėjis"
    },
    "category": "Kategorija",
    "calendar": "Kalendorius",
    "search": "Paieška",
    "searchError": "Įveskite bent tris simbolius.",
    "showLess": "Rodyti mažiau",
    "showMore": "Rodyti daugiau",
    'pagination': {
      'next': 'Kitas',
      'prev': 'Ankstesnis',
      'separator': 'iš',
      'pageSize': 'Viename puslapyje esantys elementai'
    }
  },
  "maintenance": {
    "title": "Priežiūros paslaugos",
    "text": "Dėl priežiūros paslaugos darbų akademija šiuo metu neprieinama. Galite pamėginti vėliau."
  },
  "password": {
    "requirements": {
      "title": "Slaptažodžio direktyva",
      "minimalLength": "Ilgis turi būti ne mažesnis kaip #0.",
      "mandantName": "\"Termino \"\"#0\"\" vartoti negalima.\"",
      "names": "Slaptažodyje negali būti pavartotas vardas, pavardė.",
      "userName": "Slaptažodyje negali būti pavartotas naudotojo vardas.",
      "userNameEqual": "Slaptažodyje negali būti pavartotas naudotojo vardas.",
      "bigLetters": "Turi būti / privalo ne mažiau kaip #0 didžiųjų raidžių.",
      "smallLetters": "Turi būti / privalo ne mažiau kaip #0 mažųjų raidžių.",
      "digits": "Turi būti / privalo ne mažiau kaip #0 skaičių.",
      "sameLetters": "Vienas po kito gali būti ne daugiau kaip #0 ženklų.",
      "oldPassword": "Nenaudoti seno slaptažodžio.",
      "birthday": "Slaptažodis negali sutapti su jūsų gimimo data.",
      "minSymbolsPolicy": "Turi būti / privalo ne mažiau kaip #0 specialių simbolių.",
      "minPasswordLength": "Slaptažodį sudaro ne mažiau kaip #0 ženklų.",
      "maxPasswordAttempts": "Jums liko # 0 bandymų teisingai įvesti slaptažodį."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Mes apgailestaujam!",
      "text1": "Reikiamas puslapis nerastas",
      "text2": "Naudokite savo naršyklės „Atgal“ mygtuką arba eikite į pagrindinį puslapį.",
      "button": "Grįžti į pagrindinį puslapį"
    },
    "courseCalendar": {
      "expired": "Laikas pasibaigė",
      "soonExpired": "Laikas netrukus baigsis"
    },
    "profile": {
      "userdata": "Naudotojo duomenys",
      "password": "Slaptažodis",
      "genderLab": "Kreipinys",
      "gender": {
        "men": "Ponas",
        "woman": "Ponia",
        "diverse": "Kiti",
        "unknown": "Nenurodyta"
      },
      "firstname": "Vardas",
      "lastname": "Pavardė",
      "email": "Elektroninis paštas",
      "strasse": "Gatvė / nr.",
      "city": "Pašto kodas / miestas",
      "phone": "Telefonas",
      "fax": "Faksas",
      "position": "Pozicija",
      "department": "Skyrius",
      "required": "Privalomi laukai",
      "oldPassword": "Senas slaptažodis",
      "newPassword": "Naujas slaptažodis",
      "newPasswordRep": "Kartojimas",
      "company": "Įmonė",
      "language": "Kalba"
    },
    "catalog": {
      "back": "Atgal",
      "bookingDialog": {
        "notice": "Čia įveskite mokymo prašymo pagrindą"
      }
    },
    "contact": {
      "info": "Įrašo laukelyje įrašykite savo užklausą. Jei turite omenyje specialų mokymą, prašome tai nurodyti. Tada jūsų įrašas bus persiųsta tiesiai į kompetentingą skyrių.",
      "trainingSelection": "Mokymų pasirinkimas",
      "yourMessage": "Jūsų įrašas",
      "success": "Jūsų žinutė sėkmingai nusiųsta!",
      "otherReason": "Bendras kontaktinis prašymas",
      "otherReasonSubject": "Kitas prašymas",
      "chooseReason": "Pasirinkite"
    },
    "learningProfile": {
      "title": "Mokymosi profilis",
      "unit": "Mokymo modulis",
      "points": "Bendras balų skaičius",
      "history": "Mokymosi eiga",
      "restoreFilter": "Išvalyti filtrą",
      "certificate": "Sertifikatas",
      'downloadAll': 'Atsisiųsti viską',
      "withoutCategory": "Be kategorijos",
      "tooltip01": "Jūs",
      "tooltip02": "užbaigėte",
      "tooltip03": "mokymo modulius.",
      "gamification": {
        "badge": {
          "title": "Apdovanojimas",
          "congratulations": "Sveikiname!",
          "achieved": "Gavote naują apdovanojimą!"
        }
      }
    },
    "courseDetail": {
      "title": "Mokymo informacija",
      "back": "Grįžti prie sąrašo",
      "progress": "Pažanga",
      "expiry": "Galioja iki",
      "classroomNo": "Mokymo numeris",
      "contactPerson": "Kontaktinis asmuo",
      "targetGroup": "Tikslinė grupė",
      "bonusPoints": "Papildomi balai",
      "classroomContent": "Kurso turinys",
      "webinarLight": "Internetinio seminaro informacija",
      "specials": "Ypatumai",
      "stornoConditions": "Panaikinimo sąlygos",
      "meeting": "Data",
      'freeDay': 'Free day',
      "speaker": "Instruktorius / pranešėjas",
      "location": "Mokymų vieta",
      "requested": "PRAŠOMA",
      "waitlist": "LAUKIMO SĄRAŠAS",
      "fullyBooked": "UŽIMTA",
      "edit": "Apdirbti",
      "webinarSpeaker": "Pranešėjas",
      "docsAndLinks": "Dokumentai ir nuorodos",
      "links": "Nuorodos",
      "webinar": {
        "host": "Internetinį seminarą atidaryti",
        "state": {
          "ended": "Internetinį seminarą užbaigti",
          "beforeStart": "Internetinį seminarą pradėti "
        }
      },
      "sidebar": {
        "price": "Kaina vienam asmeniui",
        "duration": "Mokymo trukmė",
        "to": "iki",
        "numberOfParticipants": "Dalyvių skaičius",
        "status": "Užsakymo statusas",
        'totalStatus': 'Bendra būklė',
        'contentStatus': 'Mokymo statusas'
      },
      "bookingInformation": {
        "title": "Užsakymo informacija",
        "note": "Pastaba",
        "accounts": "Išlaidų centras",
        "invoiceRecipient": "Sąskaitos faktūros gavėjas",
        "invoiceAddress": "Sąskaitų siuntimo adresas",
        "food": "Maitinimas",
        "arrival": "Atvykimas",
        "arrivalType": {
          "none": "Nenurodyta",
          "car": "Automobiliu",
          "train": "Traukiniu",
          "plane": "Lėktuvu"
        },
        "arrivalDate": "Atvykimo data",
        "departureDate": "Išvykimo data",
        "flightNumber": "Skrydžio numeris",
        "railwayStation": "Geležinkelio stotis"
      },
      "reserve": {
        "title": "Tinka jums ši(-os) data(-os)?",
        "text": "Patvirtinkite susidomėjimą! Tada informuosime jus dėl galimų naujų datų.",
        "button": "Pažymėti",
        "prebooked": {
          "text": "Jūs patvirtinote, kad susidomėjote šiomis pratybomis."
        }
      },
      'downloads': {
        'modalHeadline': 'Lejupielādes',
        'sidebarLink': 'Dokumentas (-ai)',
      }
    },
    "course": {
      "type": {
        "0": "Internetinis mokymas",
        "1": "Internetinis mokymas",
        "2": "Internetinis mokymas",
        "3": "Internetinis mokymas",
        "4": "Internetinis mokymas",
        "5": "Internetinis mokymas",
        "6": "Akivaizdinis mokymas",
        "7": "Internetinis seminaras",
        "-2": "Projektas",
        "undefined": "-"
      },
      "status": {
        "open": "Atviras",
        'sent': 'Sent',
        "passed": "Išlaikyta",
        'sent': 'Išsiųsta',
        "failed": "Neišlaikyta",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Laikas pasibaigė",
        "booked": "Užsakyta",
        "started": "Vykdomas",
        "upcoming": "Artėjanti",
        "running": "Vyksta",
        "completed": "Užbaigta",
        "notyet": "Kol kas dar nėra"
      },
      "notification": {
        "success1": "Pratybos ",
        "success2": " išlaikytos!"
      },
      "statusLabel": {
        "training": "Pratybos",
        'content': 'Content',
        'attendance': 'Attendance',
        "exam": "Egzaminas",
        "survey": "Apklausa",
        "efficacyControl": "Efektyvumo patikrinimas"
      },
      "start": {
        "survey": "Pradėti apklausą",
        "exam": "Pradėti egzaminą",
        "efficacyControl": "Efektyvumo patikrinimą pradėti"
      },
      "prefix": {
        "category": "Kategorija",
        "state": "Statusas",
        "type": "Pratybų tipas"
      },
      "noAssigned": "Jums nepriskirti jokie kursai.",
      "noSolved": "Jūs dar nevykdėte jokių pratybų. Pradėkite nuo pirmojo „Mano pratybos“ mokymo modulio!",
      "noAssignedBookable": "Jums nėra priskirtų jokių rezervavimo kursų.",
      "noResult": "Nerasta šiuos kriterijus atitinkančių pratybų.",
      "loadingFailed": "Reikalaujamų duomenų nepavyko įkelti dėl nežinomos klaidos!",
      "noResultInCategory": "Šiai kategorijai dar nepriskirtos jokios pratybos.",
      "meeting": {
        "noMeeting": "data nenumatyta",
        "multipleMeetings": "div. Data"
      },
      "project": {
        "courses": "Pratybos"
      }
    },
    "login": {
      "invalidUser": "Negaliojantis prisijungimas. Naudotojo vardas / Slaptažodis yra klaidingi!",
      "invalidPassword": "Negaliojantis prisijungimas. Jums liko # 0 bandymų teisingai įvesti slaptažodį.",
      "accountBlocked": "Jūsų paskyra užblokuota. Kreipkitės į tam atsakingą darbuotoją.",
      "error": "Naudotojo vardas ir/arba slaptažodis yra klaidingi!",
      "empty": "Prašome užpildyti visus laukus.",
      "back": "Grįžti prie prisijungimo",
      "description": "Įveskite savo naudotojo vardą arba el. pašto adresą, kad būtų galima jums pakartotinai išsiųsti prieigos duomenis.",
      "password-reset": {
        "description": "Prašome parinkti jūsų naudotojo sąskaitai naują slaptažodį.",
        "success": "Jūsų slaptažodis sėkmingai pakeistas.",
        "error": "Nurodyti slaptažodžiai nesutampa."
      },
      "placeholder": " Naudotojo vardas / Elektroninis paštas",
      "button": "Prieigos duomenų užklausa",
      "success": "Jūsų prašymas sėkmingai išsiųstas!",
      "errorForgot": "Nerasta naudotojo, atitinkančio nurodytus kriterijus.",
      "errorForgotSecurityQuestion": "Deja, šis atsakymas neteisingas!"
    },
    "registration": {
      "description": "Jei dar neturite prisijungimo duomenų, Jūs galite ir tiesiog užsiregistruoti, naudodami savo įmonės veiklos kodą. Atlikus šią operaciją, jūs gausite el. laišką.",
      "actionCodeError": "Įvestas registracijos kodas negalioja.",
      "actionCodeLimitExceeded": "Maksimalus leistino įvesti veiklos kodo registracijos skaičius išnaudotas.",
      "registerDescription": "Užpildykite toliau pateiktą paraiškos formą. Jūsų prieigos duomenys bus išsiųsti el. paštu po kelių minučių.",
      "title": "Jūsų registracija akademijoje",
      "required": "Privalomas laukas*",
      "emailError": "Jūsų nurodytas el. pašto adresas yra jau užimtas.",
      "emailErrorNotAllowed": "Šiuo el. pašto adresu registruotis negalima.",
      "send": "Išsiųsti",
      "completeMessage": "Sėkmingai užsiregistravote į akademiją!"
    },
    "dataProtection": {
      "accept": "Sutikti",
      "acceptText": "Taip, sutinku su naudojimo sąlygomis / duomenų apsauga"
    },
    "securityQuestion": {
      "title": "Saugos klausimas",
      "chooseQuestion": "Pasirinkite",
      "questionSelection": "Prašome pasirinkti vieną klausimą",
      "yourAnswer": "Įrašykite savo atsakymą.",
      "description": "Atsakykite į saugos klausimą ir pasirinkite naują savo naudotojo sąskaitos slaptažodį."
    },
    "faq": {
      "title": "Dažniausiai užduodami klausimai"
    }
  },
  "sidebar": {
    "all": "Visi",
    "categories": "KATEGORIJOS",
    "status": "STATUSAS",
    "courseType": "PRATYBŲ TIPAS",
    "topics": "TEMINĖS SRITYS"
  },
  "placeholder": {
    "username": "Naudotojo vardas",
    "password": "Slaptažodis",
    "actionCode": "Veiklos kodas"
  },
  "button": {
    "login": "REGISTRUOTIS",
    "login-sso": "ACTIVE-DIRECTORY PRISIJUNGIMAS",
    "send": "SIŲSTI",
    "start_course": "PRADĖTI",
    "changePassword": "Pakeisti slaptažodį",
    "reset": "Nustatyti iš naujo",
    "save": "Išsaugoti",
    "request": "Prašymas",
    "storno": "Anuliuoti",
    "close": "Uždaryti",
    "booking": "Registruotis",
    "waitlist": "Laukimo sąrašas",
    "start_webinar": "Internetinį seminarą pradėti",
    "open_webinar": "Internetinį seminarą atidaryti",
    'cancel': 'Atšaukti',
    'confirm': 'Patvirtinkite'
  },
  "bookingState": {
    "0": "PRAŠOMA",
    "1": "UŽSAKYTA",
    "2": "LAUKIMO SĄRAŠAS",
    "3": "ANULIUOTA",
    "4": "ANULIUOTA",
    "5": "ATMESTA",
    "6": "REZERVUOTA",
    "onlineTraining": {
      "0": "PRAŠOMA",
      "1": "UŽSAKYTA",
      "2": "ATMESTA"
    }
  },
  "foodPreference": {
    "none": "Nėra",
    "lactoseIntolerance": "Laktozės netoleravimas",
    "vegetarian": "Vegetariškas",
    "vegan": "Veganiškas",
    "nutAllergy": "Alergija riešutams",
    "diabetic": "Cukrinis diabetas",
    "glutenFree": "Be glitimo",
    "kosher": "Košerinis",
    "halal": "Halal",
    "other": "Kiti"
  },
  "notifications": {
    "error": "Kažkas nepavyko!",
    "errors": {
      "missingInput": "Prašome užpildyti šiuos laukus",
      "imageLoad": "Nepavyko įkelti prašomos nuotraukos"
    },
    "success": {
      "book": {
        "meeting": "Sėkmingai užsisakėte akivaizdinį mokymą.",
        "webinar": "Sėkmingai užsisakėte internetinį seminarą.",
        "course": "Sėkmingai užsisakėte pratybas.",
        "project": "Sėkmingai užsisakėte projektą."
      },
      "request": {
        "meeting": "Sėkmingai pateikėte prašymą dėl akivaizdinio mokymo.",
        "webinar": "Sėkmingai pateikėte prašymą dėl internetinio seminaro.",
        "course": "Sėkmingai pateikėte prašymą dėl pratybų.",
        "project": "Sėkmingai pateikėte prašymą dėl projekto."
      },
      "profile": {
        "updateSuccess": "Sėkmingai atnaujinote savo profilį!",
        "updatePasswordSuccess": "Sėkmingai atnaujinote savo slaptažodį!"
      },
      "securityQuestion": {
        "questionSave": "Jūsų saugos klausimas išsaugotas!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Užsakymas jau yra!",
      "noaccess": "Prieiga neleidžiama.",
      "notimplementet": "Ši funkcija šiuo metu negalima."
    },
    "success": {
      "booking": "Sėkmingai užsisakėte.",
      "request": "Sėkmingai pateikėte prašymą.",
      "cancel": "Sėkmingai anuliuota. ",
      "waitlist": {
        "add": "Jūs įtrauktas į laukimo sąrašą"
      },
      "reservelist": {
        "add": "Jūs įtrauktas į rezervacijos sąrašą"
      }
    },
    "courseCalendar": {
      "noDate": "Be termino"
    }
  },
  "trainingmanager": {
    "headline": "Tęstinio mokymo reikalavimai",
    "headlinerequest": "Pateikti pareišką",
    "bottonrequest": "Pateikti pareišką",
    "buttonmanage": "Pareiškų vykdymas",
    "requestform": {
      "requester": "Pareiškėjas:",
      "date": "Data:",
      "tab01": "Pateikti reikalavimą",
      "tab02": "Reikalavimai grupei / trečiosioms šalims",
      "tab01headline01": "Dalyvio vardas, pavardė:",
      "tab01headline02": "Tęstinio mokymo tema:",
      "tab01headline04": "Dalyvio vardas, pavardė:",
      "tab01headline03": "Kurso informacija:",
      "tab01label01": "Kreipinys:",
      "tab01label02": "Vardas:",
      "tab01label03": "Pavardė:",
      "tab01label04": "Ponas",
      "tab01label05": "Ponia",
      "tab01label06": "Kategorija:",
      "tab01label07": "Laipsnis*:",
      "tab01label08": "Trumpas aprašymas",
      "tab01label09": "Kiti:",
      "tab01label09O": "Kiti",
      "tab01label10": "Išlaidų centras:",
      "tab01label11": "Data (nuo/iki):",
      "tab01label12": "Kursų numeris:",
      "tab01label13": "Kaina:",
      "tab01label14": "Paslaugos teikėjas:",
      "tab01label15": "Priedas:",
      "tab01label16": "Pasirinkti failą",
      "tab01label17": "Nėra pasirinkto failo...",
      "tab01label18": "Pareišką išsiųsti",
      "tab01label19": "Pridėti kitą asmenį",
      "tab01label20": "Atšaukti",
      "olheadline": "Pridėti kitą dalyvį",
      "olheadline1": "Dalyvio vardas, pavardė",
      "olbodytext": "Toliau pasirinkite kitų mokymo dalyvių vardus ir pavardes.",
      "olerror": "Jokių dalyvių nerasta. Pakeiskite paieškos parametrus."
    },
    "manage": {
      "headline": "Pareiškų vykdymas",
      "headlineInfo": "Darbuotojų prašymai",
      "bodytextInfo": "Žemiau yra pateikiami visi neužbaigti tęstinio mokymo prašymai dėl tolesnio tvarkymo.",
      "newRequest": "Siųsti savo pareišką",
      "tableHeadline01": "Gavimas",
      "tableHeadline02": "Pareiškėjas",
      "tableHeadline03": "Kurso nr.",
      "tableHeadline04": "Pavadinimas",
      "tableHeadline05": "Data",
      "tableHeadline06": "Kaina",
      "tableMark": "Pažymėti viską",
      "buttonapprove": "Duoti leidimą",
      "buttondecline": "Leidimas nesuteiktas",
      "modal": {
        "refuse": {
          "headline": "Įrašas - atmesti prašymą",
          "placeholder": "Įrašykite tekstą....",
          "checkbox": "Atmesti prašymą be įrašo",
          "button": "Siųsti",
          "approval": "Duotas leidimą",
          "refuse": "Leidimas nesuteiktas"
        }
      }
    }
  }
}
