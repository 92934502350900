import { TokenValidator } from 'am-api-connector-general';
import configs from '@/config';

export default function auth({ next, router }) {
  return TokenValidator.isValid(configs.host + '/academies/api', () => {}).then(result => {
    if (result === false) {
      if (router.history.current && router.history.current.query && router.history.current.query.ref) {
        next({ path: '/?ref=' + router.history.current.query.ref });
      } else {
        next({ path: '/' });
      }
    } else {
      next();
    }
  });
}
