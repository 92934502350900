import BaseStore from '../BaseStore';

export default class UIStore extends BaseStore {
  state = {
    contactVisible: false,
    isCourse: false,
    languageSelectorVisible: false,
    userInfoVisible: false,
    mobileNavActive: false,
    securityQuestionVisible: false,
    registrationModalActive: false,
    loginModalActive: false,
    loginModalInfo: {
      meetingId: null,
      course: null
    },
    registrationInProgress: false,
    dataProtectionVisible: false,
    changePasswordModalVisible: false,
    registrationCompanyInfo: {
      companyName: '',
      companyStreet: '',
      companyCity: '',
      actionCode: '',
      meetingId: null,
      course: null
    },
    trainingsFilter: {
      category: -1,
      status: 'notStarted',
      courseType: -1
    },
    topicsFilter: {
      id: -1,
      search: ''
    },
    bookingRequest: {
      requestedId: null,
      requestType: null,
      modalActive: false,
      course: null
    },
    contactCourse: {
      courseId: null
    },
    search: ''
  };

  mutations = {
    SHOW_CONTACT_MODAL: (state) => {
      state.contactVisible = true;
    },
    SHOW_CONTACT_MODAL_COURSE: (state, payload) => {
      state.isCourse = true;
      state.contactVisible = true;
      state.contactCourse.course = payload.course;
    },
    CLOSE_CONTACT_MODAL: (state) => {
      state.isCourse = false;
      state.contactVisible = false;
    },
    TOGGLE_LANGUAGE_SELECTOR: (state) => {
      state.languageSelectorVisible = !state.languageSelectorVisible;
    },
    SHOW_LANGUAGE_SELECTOR: (state) => {
      state.languageSelectorVisible = true;
    },
    CLOSE_LANGUAGE_SELECTOR: (state) => {
      state.languageSelectorVisible = false;
    },
    SHOW_USER_INFO: (state) => {
      state.userInfoVisible = true;
    },
    CLOSE_USER_INFO: (state) => {
      state.userInfoVisible = false;
    },
    TOGGLE_USER_INFO: (state) => {
      state.userInfoVisible = !state.userInfoVisible;
    },
    SET_TRAININGS_FILTER: (state, filter) => {
      state.trainingsFilter = filter;
    },
    SET_TOPICS_FILTER: (state, filter) => {
      state.topicsFilter.id = filter;
    },
    SEARCH: (state, search) => {
      state.search = search;
    },
    ARTICLE_SEARCH: (state, search) => {
      state.topicsFilter.search = search;
    },
    TOGGLE_MOBILE_NAV: (state) => {
      state.mobileNavActive = !state.mobileNavActive;
    },
    CLOSE_MOBILE_NAV: (state) => {
      state.mobileNavActive = false;
    },
    SHOW_SECURITY_QUESTION_MODAL: (state) => {
      state.securityQuestionVisible = true;
    },
    CLOSE_SECURITY_QUESTION_MODAL: (state) => {
      state.securityQuestionVisible = false;
    },
    OPEN_BOOKING_MODAL: (state, payload) => {
      state.bookingRequest.course = payload.course;
      state.bookingRequest.requestedId = payload.requestId;
      state.bookingRequest.requestType = payload.requestType;
      state.bookingRequest.modalActive = true;
    },
    CLOSE_BOOKING_MODAL: (state) => {
      state.bookingRequest.requestedId = null;
      state.bookingRequest.requestType = null;
      state.bookingRequest.modalActive = false;
      state.bookingRequest.course = null;
    },
    SHOW_REGISTRATION_MODAL: (state, payload) => {
      state.registrationModalActive = true;
      state.registrationCompanyInfo = { ...state.registrationCompanyInfo, ...payload };
    },
    CLOSE_REGISTRATION_MODAL: (state) => {
      state.registrationModalActive = false;
    },
    SHOW_LOGIN_MODAL: (state, payload) => {
      state.loginModalActive = true;
      state.loginModalInfo = { ...state.loginModalInfo, ...payload };
    },
    CLOSE_LOGIN_MODAL: (state) => {
      state.loginModalActive = false;
    },
    SET_REGISTRATION_IN_PROGRESS: (state, payload) => {
      state.registrationInProgress = payload;
    },
    SHOW_CHANGE_PASSWORD_MODAL: (state) => {
      state.changePasswordModalVisible = true;
    },
    CLOSE_CHANGE_PASSWORD_MODAL: (state) => {
      state.changePasswordModalVisible = false;
    }
  };

  actions = {
    showContactModal: ({ commit }) => {
      commit('SHOW_CONTACT_MODAL');
    },
    showContactModalCourse: ({ commit }, payload) => {
      commit('SHOW_CONTACT_MODAL_COURSE', payload);
    },
    hideContactModal: ({ commit }) => {
      commit('CLOSE_CONTACT_MODAL');
    },
    toggleLanguageSelector: ({ commit }) => {
      commit('TOGGLE_LANGUAGE_SELECTOR');
    },
    showLanguageSelector: ({ commit }) => {
      commit('SHOW_LANGUAGE_SELECTOR');
    },
    hideLanguageSelector: ({ commit }) => {
      commit('CLOSE_LANGUAGE_SELECTOR');
    },
    toggleUserInfo: ({ commit }) => {
      commit('TOGGLE_USER_INFO');
    },
    showUserInfo: ({ commit }) => {
      commit('SHOW_USER_INFO');
    },
    hideUserInfo: ({ commit }) => {
      commit('CLOSE_USER_INFO');
    },
    filterTrainings: ({ commit }, payload) => {
      commit('SET_TRAININGS_FILTER', payload);
    },
    filterArticles: ({ commit }, payload) => {
      commit('SET_TOPICS_FILTER', payload);
    },
    search: ({ commit }, payload) => {
      commit('SEARCH', payload);
    },
    articleSearch: ({ commit }, payload) => {
      commit('ARTICLE_SEARCH', payload);
    },
    toggleMobileNav: ({ commit }) => {
      commit('TOGGLE_MOBILE_NAV');
    },
    closeMobileNav: ({ commit }) => {
      commit('CLOSE_MOBILE_NAV');
    },
    showSecurityQuestionModal: ({ commit }) => {
      commit('SHOW_SECURITY_QUESTION_MODAL');
    },
    hideSecurityQuestionModal: ({ commit }) => {
      commit('CLOSE_SECURITY_QUESTION_MODAL');
    },
    openBookingModal: ({ commit }, payload) => {
      commit('OPEN_BOOKING_MODAL', payload);
    },
    closeBookingModal: ({ commit }) => {
      commit('CLOSE_BOOKING_MODAL');
    },
    showRegistrationModal: ({ commit }, payload) => {
      commit('SHOW_REGISTRATION_MODAL', payload);
    },
    hideRegistrationModal: ({ commit }) => {
      commit('CLOSE_REGISTRATION_MODAL');
    },
    showLoginModal: ({ commit }, payload) => {
      commit('SHOW_LOGIN_MODAL', payload);
    },
    hideLoginModal: ({ commit }) => {
      commit('CLOSE_LOGIN_MODAL');
    },
    setRegistrationInProgress: ({ commit }, payload) => {
      commit('SET_REGISTRATION_IN_PROGRESS', payload);
    },
    hideChangePasswordModal: ({ commit }) => {
      commit('CLOSE_CHANGE_PASSWORD_MODAL');
    },
    showChangePasswordModal: ({ commit }) => {
      commit('SHOW_CHANGE_PASSWORD_MODAL');
    }
  }
}
