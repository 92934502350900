import { Logger } from './Logger';

export const LanguageUtil = {
  data() {
    return {
      translator: new DataTranslator(this.$store.state.mandantStore.masterLanguage, this.$i18n.locale)
    };
  },
  watch: {
    '$i18n.locale': function(locale) {
      this.translator.locale = locale;
    }
  }
};

export class DataTranslator {
  constructor(masterLanguage, locale) {
    this.masterLanguage = masterLanguage;
    this.logger = new Logger('LanguageUtil');
    this.locale = locale;
  }

  getLanguageValue(translation, locale) {
    locale = locale || this.locale || 'xx';
    if (translation === undefined || Object.keys(translation).length === 0) {
      this.logger.warning('Translation error: Received empty translation object.');
      return '';
    }

    if (translation[locale] !== '' && translation[locale] !== undefined) {
      return translation[locale];
    }

    if (translation[this.masterLanguage] !== '' && translation[this.masterLanguage] !== undefined) {
      return translation[this.masterLanguage];
    }

    if (translation['xx'] !== '' && translation['xx'] !== undefined) {
      return translation['xx'];
    } else {
      return '';
    }
  }

  isEmptyTranslation(translation) {
    if (!translation || Object.keys(translation).length === 0) {
      return true;
    }
    let allEmpty = true;

    Object.keys(translation).forEach(key => {
      if (translation[key] !== undefined && translation[key] !== null && translation[key] !== '') {
        allEmpty = false;
      }
    });

    return allEmpty;
  }
}
